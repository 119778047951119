@charset "UTF-8";
.Logo{
  /* width: 243px; */
  float: left;
}
.Logo img{
  margin-right:10px;
  margin-top: -6px;
  width: 85px;
}

.header {
  /*background: #ffffff;*/
  background-image: linear-gradient(#63aace, white);
}

.header .wrap
{
  padding-top: 15px;
}

header .header {
  padding-top: 0;
}
.home .header{
  margin-top: 8px;
}

.header a.Logo{
  margin: 0px 0 0 0px;
  padding: 0;
  font-size: 25px;
  font-weight: bold;
  font-family: 'MontserratBold';
  color: #686868;
  height: 80px;
  line-height: 61px;
} 

.header span.Logo2{
  position: absolute;
  top: 40px;
  margin-left: 6px;
  font-size: 35px;
  font-family: 'APINiceTitle2';
  color: #006aa1;
  font-weight: normal;
}

.header span#togMenu{
  background: url("../images/icones/menu.png");
  background-repeat: no-repeat;
  background-size:30px 30px;
  width: 30px;
  height: 30px;
  padding: 0;
  float: right;
  margin: 30px 0 0 24px;
  display:none;
  bottom: 6px;
}
.header:after{
  content: " ";
  clear: both;
  overflow: hidden;
  width: 100%;
  height: 0px;
  /* border-bottom: solid 1px #d9d9d9; */
  display: block;
}

.isScrolling{
  position: fixed;
  width: 100%;
  z-index: 5;
}

.header .nav,
.header li{
  float: left;
}
.header li a{
  padding: 0px 13px 13px;
  font-family: Montserrat, Arial, Helvetica, Sans-Serif;
  letter-spacing: 1px;
  height: 60px;
  line-height: 60px;
}
.header li ul li a{
  height: inherit;
  line-height: inherit;
}
.header li:last-child{
  margin-right: 12px;
}
.header li a.Active{
  font-family: MontserratBold, Arial, Helvetica, Sans-Serif;
}
.header li a:hover{  
  color: #006aa1;  
}
.header li.subMenu.hovMenu > a {
 background: none;
}
.header .subMenu{
  position: relative;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}
.header .subMenu ul{
 width: 215px;
 border: solid 1px #d9d9d9; 
 background: #ffffff;
 position: absolute;
 top: 100%;
 left: -1px;
 z-index: 1;
 border-radius: 3px;
 -moz-border-radius:3px;
 -webkit-border-radius:3px;
 display: none;
 padding: 10px 20px;
 text-align: left;
}
.header .subMenu.hovMenu{
  border-left: solid 1px #d9d9d9;
  border-right: solid 1px #d9d9d9;
}
.header .subMenu.hovMenu ul:before{
  content:"";
  width: 112px;
  height: 1px;
  background: #ffffff;
  display: block;
  position: relative;
  top: -11px;
  left: -20px;
}
.header .subMenu ul li{
 float: none;
 border-bottom: solid 1px #d9d9d9;
}
.header .subMenu ul li:last-child{
 border: none;
 margin: inherit;
}
.header .subMenu ul li a{
 padding: 5px 13px;
}
.connected .btn{
  width: auto; 
}
.connected .btns{
  width: inherit;
}
.connected .header .user{
  display: block;
  float: left;
  font-family: Montserrat, Arial, Helvetica, Sans-Serif; 
  margin-top:13px;
}
.connected .header .user.hidden{
  display: none;
}
.connected .header #toggleLogout li{
  padding: 0px 10px;
  border: none;
}
.connected .header #toggleLogout li a{
  line-height: inherit;
  height: inherit;
  padding: 6px 5px;
  border-bottom: 1px solid #d9d9d9;
}
.connected .header #toggleLogout .btBlue{
  width: 193px;
  padding: 7px 5px;
}
.connected .header #toggleLogout .btBlue:before{
  content:"";
  width:15px;
  height: 15px;
  display: inline-block;
  line-height: 15px;
  position:relative;
  right: 4px;
  top: 3px;
  background: url("../images/icones/shutdown_white.png") no-repeat;
  background-size: 13px auto;
}
.show a.Logo{
  border-bottom:1px solid #bdbdbd;
  width: 100%;
  font-size: 20px;
  height: 60px;
}
.dashboard{
  display: inline-block;
}
.dashboard.undermenu a{
  width: 116px;
}
.connected .header #toggleLogout.slide{
  width: 215px;
  border: 1px solid #d9d9d9;
  padding: 10px 0px;
  top: 46px;
  right: 6.5px;
}
.connected .bt.user{
  border-color:#d9d9d9;
}

.dashboard li.disabled{
    /*border: solid 1px #b3b3b3;*/
    background: #eae5ea;
    display: none;    
}
.dashboard li.disabled > a{
  color: #b3b3b3;
  pointer-events: none;
  cursor: default;
  border-color: #b3b3b3;
}
.dashboard li,
.connected .dashboard li.disabled,
.header .bt.btBlue.inscription,
.header .bt.connexion{
  margin-right: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}
.connectedPage .dashboard li{
  margin-right: 7px;
}
.dashboard li,
.connected .dashboard li.disabled{
  display: inline-block;
  text-align: center;
  font-size: 11px;
  background: none;
  vertical-align: middle;
}
.dashboard li .nav-link,
.connected .dashboard li .nav-link
{
  display: inline-block;
  padding:5px;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 7px 13px;
  font-size: 13px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  letter-spacing: 0.05em;
  color: #006aa1;
  /*height:40px;*/
  line-height: 26px;
  pointer-events: inherit;
  border: 1px solid #006aa1;

}
.dashboard li .nav-link:hover{
  background: #006aa1;
  color:#ffffff;
}
.connected .marBot{
  margin-bottom: 40px;
}
.header .bt.btBlue{
  margin-left: 56px;
  float: left;
}
.header .bt.btBlue.inscription,
.header .bt.connexion{
  font-family: Montserrat, Arial, Helvetica, Sans-Serif; 
  z-index: 6;
  position: relative;
  text-align: center;
  letter-spacing: 1px;
  cursor: pointer;
  text-transform: none;
  font-size: 12px;
  outline: none;
  padding: 0px 10px;
}
.header .bt.btBlue.inscription{
  color: #fff;
  background-color: #006aa1;
  margin: 13px 12px 0px 0;
  font-family: MontserratSemiBold, Arial, Helvetica, Sans-Serif;

}
.header .bt.connexion{
  margin: 20px 4px 0;
  float: right;
  color: #999999;
  background-color: #fff;
  max-width: 200px;
  border: 1px solid #d9d9d9;
  margin: 13px 0 0 0;
  display: inline-block;
  height: 33px;
  line-height: 33px;
  font-family: MontserratSemiBold, Arial, Helvetica, Sans-Serif;
}
.header .bt.btBlue.inscription:hover,
.header .bt.connexion:hover{
 /* -webkit-box-shadow: 0 0 7px 1px rgba(0,0,0,0.15);
  box-shadow: 0 0 7px 1px rgba(0,0,0,0.15);
  -moz-box-shadow: 0 0 7px 1px rgba(0,0,0,0.15);
  -ms-box-shadow: 0 0 7px 1px rgba(0,0,0,0.15);
  -o-box-shadow: 0 0 7px 1px rgba(0,0,0,0.15);*/
} 
.header .bt.btBlue.inscription:hover{
  background: #faac18;
}
.header .bt.connexion:hover{
  background: #b3b3b3;
  color: #fff;
  font-weight: 500;
}

.btns{
  float: right;
  width: 210px;
  position: relative;
}
.btns span{
 width: 168px;
}
#toggleLogout{
   display: block;
   padding-top: 15px;
}
.header #toggleLogout .btBlue,
.header #toggleLogout.slide{
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  display: block;
  width: 200px;
  margin: 0;
  float: none;
}
.header #toggleLogout.slide{
  height: auto;
  background: #ffffff;
  position: absolute;
  top: 54px;
  right: 4px;
  padding: 0;
  border: none;
}
.header #toggleLogout li{
  float: none;
  border: solid 1px #b3b3b3;
  border-bottom: none;
}
.header #toggleLogout li a{
   padding: 0 15px;

}
.header #toggleLogout li a:hover{
  color: #4d4d4d;
}
.header #toggleLogout .btBlue{
  text-align: center;
  padding-top: 0px;
  }
.header #toggleLogout li:last-child{
    border: none;
    border-right: none;
  }
   .header #toggleLogout li:first-child{
    border-radius: 3px 0 0 0;
  -moz-border-radius: 3px 0 0 0;
  -webkit-border-radius: 3px 0 0 0;
  }
  .header #toggleLogout .btBlue:hover{
    background: #faac18;
    color: #ffffff;
  }
  #menu .popup-content{
    top:47px!important;
    left: inherit!important;
    right: 7px!important;
    border: solid 1px #d9d9d9!important;
    border-radius: 5px 0 5px 5px !important;
    -moz-border-radius: 5px 0 5px 5px !important;
    -webkit-border-radius: 5px 0 5px 5px !important;
    box-shadow: none!important;
  }

.connected .bt.user{
    background-image: url("../images/icones/arrow-down.png");
    background-position: 90% center;
    background-size: 7px 7px; 
    background-repeat: no-repeat;
    width: 117px;
   /* width: 400px;*/
    text-overflow:ellipsis; 
    white-space: nowrap;  
    overflow: hidden;
    font-size: 12px;
    color: #006aa1;
    border-color: #006aa1;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #0069a1;
    color: #fff;
                 
}
.connected .bt.user:hover{
  background-image: url("../images/icones/arrow-down-white.png");    
}
.header #toggleLogout li:last-child{
  margin: 18px 0 15px 25px;
}
/*
.header a.panier{
   background-image: url("../images/icones/panier.png");
   background-position: 15px center;
   background-repeat: no-repeat; 
   background-size: 15px 14px; 
   border: solid 1px #ffae02;
   color: #ffae02!important;
   border-radius: 3px;
   -moz-border-radius: 3px;
   -webkit-border-radius: 3px;
   text-transform: uppercase;
   font-size: 12px;
   font-family: Montserrat, Arial, Helvetica, Sans-Serif;
   padding: 3px 15px 1px 34px;
   float: right;
   margin: 13px 32px 0 0;
   display: none;
}
.panier span{
   color: #ffae02;
   font-size: 12px;
   font-weight: bold;
   margin-left: 3px;
}
*/
.header .subMenu.hovMenu ul, 
.header a,
.connected .header a.publipostage,
.connectedHome .panier{
  display: block; 
}
.connected .bt.login.connexion,
.connected .inscription,
.connectedPage .notHome,
.connected .notHome,
.hideDesktop,
.mobile .connected,
.header a.user,
.connectedPage .inscription,
.header a.publipostage,
.nav .Logo,
.btns.mobile,
.header .panier,
 #menu .popup-arrow
{
  display: none;
}
@media only screen and (max-width: 1046px) {
  .btns:not(.mobile),
  .bloc nav,
  .btns,
  .header .subMenu.hovMenu ul:before,
  #mask{
    display: none;
  }
  #menu.show,
  #mask.show,
  .header span#togMenu,
  .nav .Logo,
  .header .subMenu ul{
    display: block;
  }
  .header span#togMenu{
    margin: 21px 0 0 24px;
  }
  .header span#togMenu.show{
    background: url("../images/icones/menu_mobile.png");
    background-repeat: no-repeat;
    background-size:30px 30px;
    width: 30px;
    height: 30px;
    top: -5px;
  }
  #mask{
    background: #000000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 4;
  }
  .nav .Logo{
    float: none;
    margin-left: 10px;
  }
  .header #menu,
  #menu.show{
    transition: left 0.5s;
    -moz-transition: left 0.5s;
    -webkit-transition: left 0.5s;
  }
  .header #menu{
    background: #ffffff;
    width: 275px;
    height: 100%;
    overflow-y: auto;
    position: fixed;
    z-index: 9999;
    left: -277px;
    top: 0;
  }
    #menu.show{
    left: 0;
    }
    .header .nav{
     clear: both;
    }
    .header li{
      float: none;
    }
    .header li a{
      height: inherit;
      line-height: inherit;
    }
    .header li a:hover{
      color:inherit;
    }
    .header .subMenu ul li a{
      color: #b3b3b3;
    }
    .header .wrap{
     padding-bottom: 30px;
    }
    .header li,
    .header .fl-r,
    .header .nav,
    .btns{
      float: none;
      width: 100%;
      display: block;
    }
   .header .subMenu ul li:last-child,
   .header .subMenu ul li {
      border-left: none;
      border-right: none;
   }
    .header .subMenu{
    border-top: none;
    border-bottom: none;
    width: 100% !important;
   }
   .header .subMenu ul{
    position: static;
    border: none;
    width: inherit;
    padding: inherit;
   }
  
   .header .subMenu ul li{
    border-bottom:none;
   }
   .header span#togMenu{
    cursor: pointer;
    z-index: 5;
    position: relative;
   }
 
  #menu a img{
    position: relative;
    left: 10px;
    width: 16%;
    margin-right: 15px;
  }
  .header #menu .hideMobile{
    display: none;
  }
  .header #menu a:not(.Logo){
    text-transform: uppercase;
    font-family: MontserratSemibold, Arial, Helvetica, Sans-Serif;
    border: none;
    background-color: #ffffff;
    color: #686868;
    padding: 0px;
    margin: 0;
    text-align: left;
    float: none;
    height: 33px;
    line-height: 33px;
    box-shadow: inherit;
    -moz-box-shadow: inherit;
    -ms-box-shadow: inherit;
    -o-box-shadow: inherit;
    font-size: 14px;
    padding-left: 23px;
  }
  .envois:first-child, .tarif, .faq, .publipostage, .notConnected a{
    padding-left: 0 !important;
  }
  .header #menu a:not(.Logo):hover{
    color:#3c3c3c;
    background-color: #dff1f7!important;
  }
  .header #menu a:not(.Logo):before{
    content:"";
    width: 24px;
    height: 24px;
    display: inline-block;
    background-size: 17px 17px;
    background-repeat: no-repeat;
    /*margin: 0 10px 0 27px;*/
    position: relative;
    top: 4px;
  }

  .bt.loginName:before{
    /*background-image: url('../images/icones/user-login-mobile-menu_blue.png');*/
    display: none!important;
    }
  .header #menu a.tb:before{
    background-image: url("../images/icones/dashboard_blue.svg");
    background-size: 24px 24px;
    }
  .header #menu a.publipostage:before{
    background-image: url(/static/media/logo-publipostage.7d95f97c.svg);
    width: 35px;
    background-size: 35px 27px;
    margin-left: 14px;
    margin-right: 6px;
    position: relative;
    top: 3px;
    }
  .deconnexion:before{
    background-image: url('../images/icones/panier.png');
    }
  .connexion:before{
      background-image: url('../images/icones/login_blue.svg');
      background-size: 24px 24px!important;
      position: relative;
      top: 5px;
      margin-left: 25px;
      margin-right: 8px;
  }
  .header .btns.mobile .bt.login.deconnexion:before{
    background-image: url('../images/icones/logout_blue.svg');
    background-size: 24px 24px!important;

  } 
  .header .bt.btBlue.inscription{
    border-bottom: 1px solid #bdbdbd;
    -webkit-border-radius: inherit;
    -o-border-radius: inherit;
    -moz-border-radius: inherit;
    }
  .header .inscription:before{
    background-image: url('../images/icones/app_registration_blue.svg');
    background-size: 24px 24px!important;
    position: relative;
    top: 5px;
    margin-left: 25px;
    margin-right: 8px;
    }
  .header #menu a.api:before{
    background-image: url('../images/icones/api_blue.svg');
    background-size: 24px 24px;
    }
  .header #menu a.documentation:before{
    background-image: url('../images/icones/article_blue.svg');
    background-size: 24px 24px;
    }
  .header #menu a.envois:before{
    /*background-image: url('../images/icones/enveloppe.png');
    background-size: 16px 13px;*/
    position: relative;
    top: 5px;
  }
  .header #menu .connected a.envois:before{
    background-image: url('../images/icones/mail_black.svg');
    background-size: 24px 24px;
    position: relative;
    top: 5px;
  }
  .header #menu a.envois-multiple:before{
    background-image: url('../images/icones/courrier-multiple-1.png');
    background-size: 24px 20px;
    position: relative;
    top: 6px;
  }
  .header #menu a.profil:before{
    background-image: url('../images/icones/info-perso.svg');
    background-size: 24px 24px;
    position: relative;
    top: 7px;
  }
  .header #menu a.facture:before{
    background-image: url('../images/icones/facture-icon-blue.svg');
    background-size: 24px 24px;
    position: relative;
    top: 5px;
    }
  .header #menu a.tarif:before{
    /*background-image: url('../images/icones/euro.png');
    background-size: 13px 14px;*/
    position: relative;
    top: 7px;
  }
  .header #menu a.contact:before{
    background-image: url('../images/icones/person_blue.svg');
    background-size: 24px 24px;
    }
  .header #menu a.compte:before{
    background-image: url('../images/icones/payments_blue.svg');
    background-size: 24px 24px;
    }
  .mobile .connected a:before,
  .btns.mobile a.contact:before,
  .header #menu a.documentation:before{
        margin-right: 8px;
  }
    .btns.mobile{
    float: none;
    display: block;
    }
  .mobile span{
    display: block;
    width: 100%;
    border-bottom: solid 1px #b3b3b3;
    padding: 5px 0;
  }
  .connectedPage span.notConnected,
  .connected span.notConnected
  {
    display: none;
  }
  .connectedPage span.connected,
  .connected span.connected{
    display: block;
  }
  .fl-r .Logo{
    border-bottom: 1px solid #bdbdbd;
    width: 100%;
    font-size: 20px !important;
    height: 60px !important;
  }
  .fl-r span.Logo2{
    top: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .Logo img{
      margin-right: 10px;
      width: 28%;
      height: auto;
  }
  .header .fl-r a.Logo{
    width: 100%;
  }
  .header a.Logo{
    width: 75%;
    height: auto;
    font-size: 12px;
  }
  .header span.Logo2{
    font-size: 30px;
  }
}