/*
Description:  Design Service Postal
Version:    1.0
Author:     xipe
Intégrateur:    ANDRIAMORIA Mahandrisoa Dera
*/
@charset "UTF-8";
/* CSS Document */
html{
	font-size:100%;
}
body{
	background: #ffffff;
	color: #686868;
}
* {
    box-sizing: border-box;
    margin: 0;
	padding: 0;
}
section,
article,
aside,
header, 
footer, 
nav, 
hgroup 
{
  display: block;
}
body{ 
	overflow-x: hidden;
}
table{ 
	border-spacing:0!important;
}
img{border:0;}
input{ 
	border:none transparent; 
	outline:none;
}
strong{
	font-weight: normal;
	font-family: MontserratSemiBold, Arial, Helvetica, Sans-Serif;

}
ul{
	margin-bottom: 0;
}
li{ list-style-type:none;}
input[type="text"],
input[type="tel"], 
input[type="mail"], 
input[type="submit"], 
input[type="password"], 
input[type="button"]{ 
	border-radius:8px;  
	-moz-border-radius:8px; 
	-webkit-border-radius:8px; 
	-khtml-border-radius:8px;
    appearance:none; 
    -moz-appearance:none; 
    -webkit-appearance:none;
    padding: 25px 20px;
    line-height: 2.5;
    font-size: 13px;
    color: #b3b3b3;
  }
a{
   text-decoration:none!important; 
   cursor:pointer; 
   font-size: 16px; 
   color: #006aa1;
   font-family: MontserratBold, Arial, Helvetica, Sans-Serif;
}

.hideMobile a
{
	color: #535353;
	font-size: large;
	font-weight: bold;
}

/* a:hover{
	color:#fff;
} */
label{ 
	font-size:15px;
	color: #686868;
	display: none;
}
textarea{ 
	outline:none; 
	border:none; 
}
p,
.p{ 
	padding-bottom:22px; 
	font-size: 14px;
    font-family: Montserrat, Arial, Helvetica, Sans-Serif; 
    color: #535353; 
    line-height: 23px;
    margin-bottom: 0; 
}
span{
	font-family: Montserrat, Arial, Helvetica, Sans-Serif;
	color: #b3b3b3; 
    line-height: 27px;
}
h1,
h2,
h3,
h4,
h5,
h6{
	font-weight:normal;
}

h4
{
	font-size: 18px;
	color: #535353;
}

h2,
.content .bloc p.h2{ 
	font-family: MontserratBold, Arial, Helvetica, Sans-Serif; 
	font-size: 34px; 
	color: #686868;
	line-height: 36px;
}
h3{ 
	font-family: MontserratBold, Arial, Helvetica, Sans-Serif; 
	font-size: 24px; 
    color: #686868;
}
ul,
li{
	color: #666;
}
h1,
.Home h2{
	font-family: MontserratBold, Arial, Helvetica, Sans-Serif;
    font-size: 36px;
    color: #686868;
    line-height: 36px;
}
.mt20{
	margin-top: 20px;
	font-size: 16px !important;
}
/********** Fonts *********/
@font-face {
 font-family:'Montserrat';
 src:url("../fonts/Montserrat/Montserrat-Regular.eot");
 src:url("../fonts/Montserrat/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
 url("../fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"),
 url("../fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
 url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype"),
 url("../fonts/Montserrat/Montserrat-Regular.svg#Montserrat") format("svg");
 font-weight:400;
 font-style:normal
}
@font-face {
 font-family:'MontserratBold';
 src:url("../fonts/Montserrat/Montserrat-Bold.eot");
 src:url("../fonts/Montserrat/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
 url("../fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
 url("../fonts/Montserrat/Montserrat-Bold.woff") format("woff"),
 url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype"),
 url("../fonts/Montserrat/Montserrat-Bold.svg#MontserratBold") format("svg");
 font-weight:700;
 font-style:normal
}
@font-face {
 font-family:'MontserratSemiBold';
 src:url("../fonts/Montserrat/Montserrat-SemiBold.eot");
 src:url("../fonts/Montserrat/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
 url("../fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2"),
 url("../fonts/Montserrat/Montserrat-SemiBold.woff") format("woff"),
 url("../fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
 font-weight:600;
 font-style:normal
}
@font-face {
 font-family:'MontserratMedium';
 src:url("../fonts/Montserrat/Montserrat-Medium.eot");
 src:url("../fonts/Montserrat/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
 url("../fonts/Montserrat/Montserrat-Medium.woff2") format("woff2"),
 url("../fonts/Montserrat/Montserrat-Medium.woff") format("woff"),
 url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
 font-weight:600;
 font-style:normal
}
@font-face {
 font-family:'MontserratLight';
 src:url("../fonts/Montserrat/Montserrat-Light.eot");
 src:url("../fonts/Montserrat/Montserrat-Light.eot?#iefix") format("embedded-opentype"),
 url("../fonts/Montserrat/Montserrat-Light.woff2") format("woff2"),
 url("../fonts/Montserrat/Montserrat-Light.woff") format("woff"),
 url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
 font-weight:600;
 font-style:normal
}

@font-face {
	font-family: 'APINiceTitle1';
	src: url(../fonts/InkBrush/Inkbrush.eot);
	src: url(../fonts/InkBrush/Inkbrush.eot?#iefix) format('embedded-opentype'),
	url(../fonts/InkBrush/InkBrush.otf) format('otf'),
	url(../fonts/InkBrush/Inkbrush.svg#Inkbrush) format('svg'),
	url(../fonts/InkBrush/Inkbrush.ttf) format('truetype'),
	url(../fonts/InkBrush/Inkbrush.woff) format('woff'),
	url(../fonts/InkBrush/Inkbrush.woff2) format('woff2');
}
@font-face {
	font-family: 'APINiceTitle2';
	src: url(../fonts/BackGroove/BackGroove.eot);
	src: url(../fonts/BackGroove/BackGroove.eot?#iefix) format('embedded-opentype'),
	url(../fonts/BackGroove/BackGroove.otf) format('otf'),
	url(../fonts/BackGroove/BackGroove.svg#BackGroove) format('svg'),
	url(../fonts/BackGroove/BackGroove.ttf) format('truetype'),
	url(../fonts/BackGroove/BackGroove.woff) format('woff'),
	url(../fonts/BackGroove/BackGroove.woff2) format('woff2');
}

.clear{
	clear: both;
	overflow: hidden;
	width: 100%;
	height: 0;
}

.wrap{
	max-width: 1170px;
	margin: 0 auto;
	clear: both;
	padding: 0 30px;
}
.m-auto{
	margin: 0 auto;
}
.bt{
	background: none;
	border: solid 1px #b3b3b3;
	color: #b3b3b3;
	border-radius: 3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	height: 33px;
	line-height: 33px;
	padding: 0 22px;
	display: inline-table;
    margin: 0 7px;
    font-family: MontserratBold, Arial, Helvetica, Sans-Serif;
    font-size: 12px;
}
.bt:hover{
	background: #b3b3b3;
	color: #ffffff;
}
.btBlue{
	background: #006aa1;
	color: #ffffff;
	border: none;
}
.btBlue:hover{
    background: #faac18;
}
.fl-r{
	float: right;
}
.fl-l{
	float: left;
}
.plr-30{
	padding-left: 30px;
	padding-right: 30px; 
}
.pl-15{
	padding-left: 15px
}
.mb-14{
	margin-bottom: 14px;
}
.w55{
	width: 55%;
}
.w45{
	width: 45%;
}
.al-c{
	text-align: center;
}
.al-r{
	text-align: right;
}
.al-l{
	text-align: left;
}
.note{
	font-size: 13px;
	color: #535353;
}
.wrap730{
	max-width: 730px;
	margin: 0 auto;
}
.blue{
	color: #006aa1;
}
.sz-14{
	font-size: 14px;
	display: inline-block;
}
.sz-18{
	font-size: 18px;
}
.chat{
	background: url("../images/icones/chat.png");
	background-repeat: no-repeat;
	background-size: 73px 73px;
    width: 73px;
    height: 73px;
    display: none;
	position: fixed;
	right: 0;
	bottom: 40%;
	z-index: 3;
}
.pos-r{
	position: relative;
}
.w-65{
	width: 65%;
}
.mx-452{
	max-width: 452px;
}
.mx-502{
	max-width: 502px;
}
.mx-800{
	max-width: 800px;
}
.pl-35{
	padding-left: 35px;
}
.bt2{
	margin-left: 0;
	max-width: 100%;
	padding: 0 16px;
	margin-right: 0;
}
.bt-fl button{
	float: left;
	margin-left: 0;
}
.btBlue{
	margin-right: 15px;
}
.mt-7{
	margin-top: 7px;
}
.Home, .home{
	margin-top: -8px;
}
hr{
	margin-top: 40px;
	margin-bottom: 43px;
	border-bottom: solid 1px #b3b3b3;
}
.home{
	padding: 0;
}
.popup-content{
	border-radius: 8px !important;
	-moz-border-radius: 8px !important;
	-webkit-border-radius: 8px !important;
	-khtml-border-radius: 8px !important;
}
.popup-content .close{
	position:absolute;
	top: -13px;
	right: -12px;
	background: #006aa1;
	color:#ffffff !important;
	opacity:1;
	width:25px;
	height:25px;
	text-align: center;
	font-size: 24px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-khtml-border-radius: 50%;
}
.popup-content .close:hover{
	opacity:1 !important;
	background: #faac18;
}
@media only screen and (max-width: 1100px) {
  	.wrap{
  		padding: 0 30px;
  	}
}
@media only screen and (max-width: 956px) {
	.w55{
		width: 100%;
		float: none;
	}
	.w45{
		width: 100%;
		float: none;
	}
	.w45{
		text-align: center;
	}
  	.Logo{
  		margin-bottom: 18px;
  	}
  	.note{
  		margin: 15px 0;
  		display: block;
  	}
  	.w50.fl-r.al-r{
  		text-align: center;
  	}
  	.popup-content{
  		width: 100%;
  	}
}
