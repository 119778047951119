/***content mobile style *********/

@media only screen and (max-width: 1180px) {
	.btBlue{
		margin-bottom: 14px;
	}
	.connected .content .monCourrier{
		width: 364px;
	}
	.connectedPage.connected .bloc.tabTitle{
		width: 58%;
	}
	.connectedPage.connected .bloc.tabTitle.apikeys{
		margin-top: 0px;
	}
	.connectedPage.connected h1,
	.connectedPage.connected h2
	{
		text-align: left;
	}
	.connectedPage.connected .content .apikeys .input-group-append .btBlue{
		margin: 0px;
		position: initial;
	}
	.connectedPage.connected .content .apikeys button.bt2.second{
		vertical-align: bottom;
	}
	.connectedPage.connected .bloc-tableau .top span, .bloc-tableau .top span a{
		 font-size: 12px;
	}
	.factures .text-center{
		width: 100%;
		left:0;
		margin: 0;
		border-radius: 0;
		-moz-border-radius: 0;
		-webkit-border-radius: 0;
		-o-border-radius: 0;
	}
  }
  @media only screen and (max-width: 1151px){
	  .tableau{
		  overflow-x: auto;
	  }
  }
  @media only screen and (max-width: 1056px){
  .content .elt.tarifs .consumption.priority .nbCourrier .detail p {
	  font-size: 11px;
	 }
  }   
  @media only screen and (max-width: 1046px){
	  .connected .content .monCourrier{
			width: 100%;
		}
		.connected .content .aside{
			width: auto;
	  }
		.content .bloc.tabTitle{
			padding-top: 0px;
		}
		.connectedPage.connected .content .apikeys .input-group-append{
			right: -4px;
		}
		.content.tabs.api{
			padding-top: 20px;
		}
		.connectedPage.content .bloc.tabTitle{
		  width: 100%;
	   margin-top: 0px;
	  position: relative;
	  z-index: 2;
	}
	.connectedPage.content .bloc-tableau{
	  max-width: inherit;
	}
	  .connectedPage.connected .bloc.tabTitle,
	  .blocArrondi.w-65{
		width: 100%;
	}
  }
  @media only screen and (max-width: 1032px){
	  .dashboard li .nav-link{
			font-size: 12px;
		}
  }
  @media only screen and (max-width: 1024px){
	  .hideMob{
		  display: none;
	  }
	  .mobVersion{
		  display: block;
	  }
	  .mb-block{
		  display: block;
	  }
	  .mb-block.documents{
		display: flex;
		margin-bottom: 5px;
	  }
	  .mb-block.documents a:last-child{
           order: 1;		   
	  }
	  .mb-block.documents a:first-child{
           order: 2;
           font-weight: bold;
           /*position: relative;
           top: 14px;
           left: 5px;*/
		   margin-top: 14px;
		   margin-left: 10px;
		   margin-bottom: 6px;
	  }
	  .mb-block.documents:last-child{
		margin-left: -4px;
	  }

	  .mb-block.name{
		  margin-top: 15px;		  
	  }
	  .mb-block strong{
		color: #006aa1 !important;
		font-weight: bold;
	  }	  
	  .mb-block.suivre img{
		margin-right: 5px;
	  }
	  .mb-block button{
		border: none;
		background: none;
		color: #faac18;		
		font-size: 14px !important;
	  }
	  .mb-block button:nth-child(2){
		margin-left: -4px;    
    	margin-right: 5px;
		font-weight: normal;
	  }
	  .mb-block button:nth-child(1){
		order: 1;		
	  }
	  .mb-block.name p{
		  text-align: left;
	  }
	  .mb-block p{
		  border: none;
	  }
	  .case.tbody{
		  width: 100%;
	  }
	  .mob-line{		
		  border-bottom: 0;
	  }
	  .casex.case3{
		  border-left: 0px;		
		  border-left: 1px solid #dddddd !important;		
	  }
	  .casex.case2{
		  width: 100%;
		  text-align: right;
	  }
	  .casex.case3 img{
		  display: inline;
		  margin-left: 5px;
	  }
	  
	  .case p, .case .casex{
		  border: none;
	  }
	  .case p{
		  padding-left: 0;	
	  }	
	  .case span, .case strong{
		  color: #4b4e53;
		  font-size: 12px;
	  }
	  .case .popup-content{
		  display: none;
	  }	
	  .mb-block p{
		  font-weight: bold;
		  color: #4b4e53 !important;
		  font-size: 12px;
	  }
	  .mb-block p span{
		  text-align: left;
		  display: block;
		  font-weight: normal;
	  }
	  .mb-block.options strong{
		  display: block;
		  text-align: left;
	  }
	  .mb-block.options{
		  padding-bottom: 30px;
	  }
	  .mb-block.options .bold{
		  font-weight: bold;		
	  }
	  .mb-block.options span{
		  display: inline;
		  margin-right: 5px;
	  }
	  .mb-block a{
		  display: block;
		  color: #ffae02;			
	  }
	  .mb-block.suivre{
		  padding-top: 10px;
	  }
	  .mb-block.suivre span{		  		
		  display: inline;
		  color: #faac18
	  }
	  .mb-block.suivre i{
		  color: #ffae02;
		  margin-top: 2px !important;				
		  display: inline;
		  float: left;
	  }
	  .mobVersion .full{
		  border-top: 1px solid #dddddd;
		  border-left: 1px solid #dddddd;
		  border-right: 1px solid #dddddd;
		  position: relative;
	  }
	  .mobVersion .part{
		  display: inline-block;
		  width: 92%;		
		  vertical-align: middle;
		  padding-left: 15px;
		  padding-bottom: 15px;
	  }
	  .mobVersion .archive-block{
		  position: absolute;
		  display: inline-block;
		  width: 64px;
		  height: 100%;				
		  right: 0;
		  border-left: 1px solid #dddddd;
	  }
	  .case.tbody .casex:first-child{
		  border: none;
	  }
	  .casex.case2 .material-icons-outlined{
		  font-size: 35px;
		  margin-top: 7px;
	  }
	  .archive-block .case12{
		  width: 100%;
		  max-width: inherit;
		  min-width: inherit;
		  position: absolute;
		  top: 40%;
	  }
	  .envois-nombre{
			border: 1px solid #dddddd;
			border-bottom: 0;
		}	
	  .envois-nombre .case{
		  display: block;
	  }
	  .envois-nombre .full{
		  padding: 15px;
		  border: 1px solid #dddddd;
	  }
	  .envois-nombre .mb-block strong{
		  display: block;
		  text-align: left;
	  }
	  .envois-nombre .mb-block span{
		  display: inline;
		  margin-right: 5px;
	  }	 
	  .envois-nombre .doc{		
		  width: 50%;
	  }
	  .envois-nombre .doc a{		
		  display: block;		
	  }
	  .envois-nombre .doc span{
		  color: #ffae02;
		  font-weight: bold;
		  display: inline-block;
		  vertical-align: top;
		  padding-top: 8px;
		  margin-left: 5px;
	  }
	  .mobVersion.hide{
		  height: 60px;
		  overflow: hidden;
	  }
	  .rc-collapse{
		  display: block;
	  }
	  .rc-collapse > .rc-collapse-item > .rc-collapse-header{
		  position: relative;
	  }
	  .rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow{
		  position: absolute;
		  top: 16px;
		  right: 20px;
		  transform: rotate(90deg);
		  -webkit-transform: rotate(90deg);
		  border-top: 6px solid transparent;
		  border-bottom: 6px solid transparent;
		  border-left: 10px solid #666;
	  }
		
	  .rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow{
		  position: absolute;
		  top: 16px;
		  right: 22px;
		  transform: rotate(-90deg);
		  -webkit-transform: rotate(-90deg);
	  }
	  .tableau{
		  min-width: inherit;
	  }	
	  .mobVersion .arrows{
		width: 0;
		height: 0;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-top: 7px solid #555;
		margin-top: 20px;
		display: inline-block;
		margin-bottom: 14px;
		margin-right: 8px;
	  }
	  .mobVersion .is-open .arrows{
		border-top: 0;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-bottom: 7px solid #555;
	  }
	  .header-collapse{
		  border: 1px solid #dddddd;
		  border-bottom: 0;
	  }
	  .case .casex.case3{		
		min-width: 155px;
		text-align: left;		
		text-transform: uppercase;
	  }
	  .tableau .line:nth-child(even){
		  display: inherit;
	  }
	  .content .bloc p, .content .bloc li{
		  text-align: left;
	  }
	  .BlocPagination .rc-pagination li{
		  text-align: center;
		  margin: 0;
	  }
	  .tarifs.content .bloc p{
		text-align: center;
	  }
	  .ArScanLink img{
		display: block;
		margin-left: 5px;
		margin-top: 15px;		
	  }
	  .documents .arScan-content i{
		font-size: 20px;
		color: #2aa4e5 !important;
		opacity: 1!important;
	  }
	  .case .popup-content.arScan-content{
		display: block !important;
		z-index: 99;
	  }
	  .rc-pagination{
		display: flex;
		clear: both;		
		justify-content: center;
	  }
  }
  @media only screen and (max-width: 956px){
	  .content .elt.tarifs .option .more,
	  .content .elt.tarifs .option .prix{
			width: 50%;
		}
  }
  @media only screen and (max-width: 900px) {
	.top .fl-r,
	.top .fl-l{
		float: none;
		text-align: center;
	}
	.content .elt.tarifs .consumption .nbCourrier p{
		font-size: 12px;
	}
	.legende span{
		width: 200px;
	}
	.factures .text-center .text-info{
		top: 53%;
	}
  }
  
  @media only screen and (max-width: 868px) {
	.content .elt.tarifs .option .nbPage {
		height: 58px;
	}
	.contact .callUs .numberCall{
		font-size: 28px;
		padding-top: 10px;
	}	
  }
  @media only screen and (max-width: 821px) {
	  .content .elt.tarifs .consumption.bgBlue .nbCourrier{
		  min-height: 97px;
	  } 
	  .content .elt.tarifs .popup-content .detail{
		padding: 8px;
	} 
	  
  }
  @media only screen and (max-width: 800px) {
	.content .bloc.bord{
		margin: 0 30px;
	}
	.content .bloc .popup-content,
	.connectedPage.content .bloc .popup-content{
		margin: 0px;	
	}
	.connectedPage.connected .bloc-tableau .top span, .bloc-tableau .top span a{
		display: table-cell;
		width: 234px;
		font-size: 12px;
	}
	.connectedPage.connected h2{
		font-size: 20px;
	}
	.content .bloc-tableau p{
		margin-left: 0px;
	}
	.content .elt.tarifs .consumption .nbCourrier{
		width: 174px;
	}
	.content .elt.tarifs .consumption{
		width: inherit;
	}
	.content .elt.tarifs .center .nav-item{
		padding: inherit;
	}
	.overflowX{
	  width: 100%;
	  overflow-x: scroll;
	}
	.overflow{
	  width: 700px;
	}
	.blocArrondi.blocArrondiParent,
	.content .bloc .elt.contactForm{
		padding: 0;
	}
	 .contact .blocArrondi,
	 .dashboard .contact .blocArrondi,
	 .contact-page .contact .blocArrondi
	{
		max-width: 100%;
		border: none;
	}
	.contact .callUs .serviceCall{
		width: 193px;
	}
	.connectedPage .loadParent .loader .text-info{
		color: #ffffff !important;
	}
	.content .elt.tarifs .consumption.option .nbCourrier{
		width: 25%;
	}
	.envois-nombre .doc{
		width: 100%;
	}
	.content .flex-container{
		flex-direction: column;
	}
	.content .flex-item:first-child{
		border: none;		
	}
	.flex-item{
		padding: 0;
	}
	.grid-elt h4{
		margin-bottom: 5px;
	}
  }
  
  @media only screen and (max-width: 600px) {
	.childBloc{
		float: none;
	  width: calc(100% - 30px);
	}
  
	.childBloc:last-child{
		padding-top: 0;
	}
	.contactForm .contact .callUs .numberCall{
		font-size: 24px;
		padding-top: 0;
		border-right: 2px solid #cecdcd;
	}
	.contactForm .contact .callUs .serviceCall{
		font-size: 12px;
		padding: 5px 0;
	}
	.contact .callUs .serviceCall:before{
		display: none;
	}
	.contactForm .contact .callUs .numberCall,
	.contactForm .contact .callUs .serviceCall{
		 display: block;
		 text-align: center;
		 width: 100%;
	}
	.connectedPage.connected .apikeys .form-group .btBlue{
		margin-right: 4px;
		width: 100%;
	}
	.connected .apikeys .inlineBlock{
		float: none;
		width: 100%;
		max-width: inherit;
	}
	.apikeys .text-center{
		position: initial;
	}
	.apikeys .btn{
		max-width: inherit;
	}
	.legende span{
		width: 50%;
		margin-right: 0;
	}
  
	.content .elt.tarifs .option .nbPage{
		padding: 11px 0px;
	}
  
	.text-right .btBlue {
		margin-right: 0;
		width: 100%;
		max-width: 100%;
		margin: 0;
	}
	.contact .callUs > p {
		padding-top: 30px;
	}
	.childBloc.center{
		margin: 20px auto;
	}
	.factures .text-center .text-info{
		top: 56%;
	}
	.contact .callUs .serviceCall, .dashboard .contact .callUs .serviceCall{
		position: initial;
	}
	.tableEcosysteme{
		width: 100% !important;
		display: block;
	}
	
	.tableEcosysteme img{
		margin: 15px 0;
	}
	.blocNumber{
		display: block;
		height: auto;
	}
	
  }  
  
  @media only screen and (max-width: 560px) {
	.blocArrondi.plr-30 .fl-l,
	.blocArrondi.plr-30 .fl-r {
	  float: none;
	  clear: both;
	}
	.blocArrondi.plr-30 .checkbox{
		margin-bottom: 20px;
	}
	.childBloc.fl-r{
		position: relative;
		padding-top: 20px;
	}
	.childBloc.fl-r p{
		position: absolute;
		top: 50px;
		right: 0;
	}
	.childBloc.fl-r .form-group{
		height: 40px;
	}
	.blocArrondi.plr-30 .checkbox.fl-r{
		margin-bottom: 40px;
	}
	.plr-30 .w-100.btBlue{
		width: 100%;
	}
	.partner a img{
		max-width: 100%;
	  height: auto;
	}
	.content .bt.btBlue{
		width: 100%;
	  max-width: 100%;
	}
	.childBloc.center{
	  width: calc(100% - 30px);
	  min-width: inherit;
	}
	.content .bt.btYellow{
		max-width: inherit;
		width: auto;
	}	
	.content .elt.tarifs .popup-content .detail{
		padding: 8px;
	}
	#left-tabs-tab-first.active, #left-tabs-tab-second.active{
		border-radius: 5px;
	}
	#left-tabs-tab-first, #left-tabs-tab-second{
		border: 1px solid #b7b7b7 !important;
		border-radius: 5px;
	}
	.rc-pagination{
		margin-top: 5em;
	}
  }
  @media only screen and (max-width: 480px) {
	.w45 img{
		 width: 100%;
		 height: auto;
	}
	.popup-content{
		width: 75% !important;
	}
	.popup-content .formulaire{
		width: 100% !important;
	}
	.connectedPage.connected .content .apikeys .input-group .form-control{
		border-radius: 8px;
		-o-border-radius: 8px;
		-webkit-border-radius: 8px;
		-moz-border-radius: 8px;
	}
	.connectedPage.connected .content .apikeys .input-group-append{
		margin-top: 10px;
		position:initial;
		height: inherit;
		margin-left: 0px;
	}
	.connectedPage.connected .content .apikeys .input-group-append .btBlue{
		margin: inherit;
		height: 33px;
	}
	.connectedPage.connected .content .apikeys .btn{
		max-width: inherit;
		width: 100%;
		border-radius: 5px !important;
		-o-border-radius : 5px !important;
		-webkit-border-radius : 5px !important;
		-moz-border-radius : 5px !important;
	}
	.connectedPage.connected .content .apikeys .inlineBlock{
		display: block;
		width: 100%;
	}
	.connectedPage.connected .content .apikeys .inlineBlock .btBlue{
		margin-left: 0px;
	}
	.connectedPage.connected .content .apikeys button.bt2,
	.contactForm .contact input.pull-right,
	.legende span{
		width: 100%;
	}
	.bloc-tableau strong{
		display: block;
	}
	.connected .fieldGroup button{
		position: initial;
		height: 33px;
	}
	.connectedPage.connected .content .apikeys button.bt2.first{
	  margin-top: 0px;
	}
	;contact input.pull-right{
		float:none;
		clear :both;
		margin-top: 10px;
	}
	.content .elt.tarifs .option .more{
		padding-right: 0px;
	}
	.content .elt.tarifs .option p,
	.content .elt.tarifs .option span{
		font-size: 12px;
	}
	.partner h2{
		text-align: center;
	}
	.partner a{
		width: 40%;
	}
	.content .elt.tarifs .option .nbPage{
		padding: 11px 0px;
	}
	.content .elt.tarifs .option .prix,
	.content .elt.tarifs .option .more
	{	
		height: 60px;
	}
	.connected .plr-30 .fieldGroup button{
	  position: absolute;
	}
	.connected .plr-30 .fieldGroup button {
		position: absolute;
	}
	.mobVersion .part{
		width: 80%;
		text-align: left;
	}
	.mobVersion .archive-block{
		width: 50px;
	}
	.mobVersion .case1, .mobVersion .case3, .mobVersion .case2{
		min-width: inherit;
		width: 100%;
	}
	.mb-block.options p{
		text-align: left;
	}
	.case .casex.case3{		
		min-width: 81px;
		text-align: center;		
		text-transform: inherit !important;
	}
	.header a.Logo{
		font-size: 16px;
	}	
	.content .elt.tarifs .popup-content{
		width: 100% !important;		
	}
	.grid-elt{
		display: block;
		text-align: center;
		border-bottom: 1px solid #ccc;
		padding-bottom: 3em;
	}
	.grid-elt:last-child{
		border-bottom: 0;
	}
	.grid-elt a{
		font-size: 11.8px;
		display: inline;
	}
	.rc-pagination a{
		width: 28px;
	}
  }
  @media only screen and (max-width: 360px) {
	.popup-content,
	.popup-content .formulaire{
		width: 100% !important;
	}
	.popup-content .close{
		top:-3px;
		right:-3px;
	}
	.connectedPage.connected .blocHide.slidePrev{
	  overflow: hidden;
	}
	.connected .bloc-tableau strong{
		display: inline;
	}
	.mb-block.options{
		font-size: 15px;
	}
	.childBloc .form-group{
		margin-bottom: 3em;
	}
	.childBloc.fl-r p{
		top: 60px;
	}
	.header a.Logo{
		font-size: 14px;
	}	
	.a2{
		background: none;
		padding: 0;
		font-weight: bold;
		text-decoration: underline !important;
	}
	.liens_footer div{
		width: 100% !important;
	}	
	.sociaux{
		text-align: center !important;
	}
	.lien-sociaux{
		justify-content: center !important;
		padding-top: 15px;
	}
	.status a{
		margin: auto;
	}	
  }  