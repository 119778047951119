@charset "UTF-8";

.react-codemirror2{
    padding: 0 10px;
}
.react-codemirror2 .CodeMirror{
	height: 400px;
	border-right: solid 1px #f3f4f8;
	border-left: solid 1px #f3f4f8;
	border-radius: 3px;
	-moz-box-shadow: 0px 2px 3px 11px #fafbfd;
    -webkit-box-shadow: 0px 2px 3px 11px #fafbfd;
    -o-box-shadow: 0px 2px 3px 11px #fafbfd;
    box-shadow: 0px 2px 3px 11px #fafbfd;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#fafbfd, Direction=180, Strength=3);
}
.react-codemirror2 .CodeMirror-gutters{
	border-right:none;
	background: #f2feef;

}
.react-codemirror2 .CodeMirror-linenumber{
	color: #9edfc1;
	text-align: center;
}
.react-codemirror2 .CodeMirror pre span{
	font-size: 12px;
	line-height: 17px;
	color: inherit;
}
.bottom_mirror{
	background: #f6f9fc;
	height: 26px;
	border: solid 1px #f3f4f8;
	border-top: none;
	width: calc(100% - 20px);
    margin: 0 auto 20px;
    position: relative;
    border-radius: 2px 3px 5px 5px;
    -moz-border-radius: 2px 3px 5px 5px;
    -webkit-border-radius: 2px 3px 5px 5px;
    -moz-box-shadow: 0px 11px 0px 11px #fafbfd;
    -webkit-box-shadow: 0px 11px 0px 11px #fafbfd;
    -o-box-shadow: 0px 11px 0px 11px #fafbfd;
    box-shadow: 0px 11px 0px 11px #fafbfd;
    filter:progid:DXImageTransform.Microsoft.Shadow(color=#fafbfd, Direction=180, Strength=3);
}
.content .bloc .powered a{
	font-weight: bold;
  display: inline-block;
  margin-left: 3px;
}
.content .bloc .powered span{
  float: left;
}
.content .bloc .powered span, 
.content .bloc .bottom_mirror a{
	color: #6772e4;
	font-size: 11px;
  line-height: 21px;
}
.content .bloc .powered{
	padding-top: 2px;
    padding-left: 10px;
    display: inline-block;
    float: left;
}
.powered form{
  float: left;
      margin-top: 2px;
    margin-left: 22px;
}
.bt_code{
	display: inline-block;
    float: right;
}
.content .bloc .bottom_mirror a.run{
	background: url("../images/icones/fleche.jpg") #12b47d scroll 8px center ;
	background-repeat: no-repeat;
    width: 48px;
    height: 26px;
    display: inline-block;
    margin-left: 8px;
    color: #ffffff;
    font-weight: bold;
    padding: 2px 0 0 21px;
    border-radius: 0 0 3px 0;
     -moz-border-radius: 0 0 3px 0;
    -webkit-border-radius: 0 0 3px 0;
    position: relative;
    right: -1px;
}
#ControlSelect.form-control{
  border: none;
  padding: 0;
  background: #f3f4f8;
  color: #6772e4;
  width: auto;
  font-size: 11px;
  line-height: 5px;
  height: 17px;
}
.react-codemirror2 .CodeMirror-vscrollbar,
	.react-codemirror2 .CodeMirror-hscrollbar,
	.react-codemirror2 .CodeMirror-scrollbar-filler{
		z-index: 0;
	}
@media only screen and (max-width: 673px) {
	
}