/***content style *********/

@charset "UTF-8";
*{
	margin: 0px;
}

html, body{
	height: 100%;
}
.content .wrap:after {
  content: "";
  display: block;
}
.footer .wrap{
	margin-bottom: inherit !important;
}
.footer .wrap:after{
	display: none;
}
.no-border{
	border:none !important;
}
.inlineBlock{
	display:inline-block;
	vertical-align: top;
	float: left;
	position: relative;
}
.nobackground{
	background: #ffffff;
}
.calculator:before{
	content:"";
	display : inline-block;
	width: 30px;
	height: 25px;
	background: url("../images/icones/calculator.png") no-repeat;
	background-size: 20px auto;
	float:right;
	position:relative;
	top: 3px;
}
.icon_eye:before{
	content:"";
	display : inline-block;
	width: 30px;
	height: 25px;
	background: url("../images/icones/eye.png") no-repeat;
	background-size: 22px auto;
	float:right;
	position:relative;
	top: 9px;
}
.input-group-append .radius{
	border-radius: 0 8px 8px 0 !important;
	-moz-border-radius: 0 8px 8px 0 !important;
	-webkit-border-radius: 0 8px 8px 0 !important;
}
.content a,
.content .bloc li,
h1.fl-l,
.childBloc.fl-r .labelShow,
.content .elt.tarifs .center .btn:last-child,
.casex,
.dashboard li.menuEnvois .popup-content a,
.checkbox label{
	font-family: Montserrat, Arial, Helvetica, Sans-Serif;
}
.casex button{
	border: none;
	background: none;
}
.content a{
	color: #006aa1;
	font-size: 12px;
}
.content .bt{
	color: #b3b3b3;
}
.content .bt.btBlue,
.content .bt:hover{
	color: #ffffff;
}
.content{
	padding-top: 20px;
	min-height: 591px;
	position: relative;
}
.connected .content .tabs{
	padding-top:inherit;
}
.content .wrap {
  margin-bottom: 32px;
}
.connectedPage .content.api .footer .wrap{
	margin-bottom: 0px;
}
.content .bloc.bord{
	border-top: solid 2px #f2f2f2;
	padding: 30px 0;
}
.content .bloc p,
.content .bloc .p,
.content .bloc li{
    color: #424242;
    font-size: 18px;
    line-height: 28px;
}
.contact-page .bloc p{
	font-size: 15px;
}
.tableau .case p{	
	font-size: 13px;
}

.content .bloc ul{
	font-size: 14px;
	line-height: 27px;
	padding-bottom: 10px;
}
.content .bloc p.sz-20{
	font-size: 20px;
	padding-top: 5px;
}

/*.code{
	background: url("../images/icones/code.png") scroll right 28px;
	background-size: 52px 52px; 
	background-repeat: no-repeat;
}*/
.content .bloc p.sz-18{
	font-size: 18px;
}
.content .bt,
.content .bloc ul,
.content .bloc p.sz-18,
.content .bloc p.sz-20,
.rc-pagination a,
.partner h2,
.connectedPage.connected h1,
.connectedPage.connected h2{
	font-family: MontserratSemiBold, Arial, Helvetica, Sans-Serif;
}
.sz-18.pos-r{
	padding-right: 70px;
}
.bloc.code li{
	background: url("../images/icones/bullet.png") center left ;
	background-size: 3px 3px; 
	background-repeat: no-repeat;
	padding-left: 8px;
}
.intro p{
  font-size: 14px;
}
.wrap.connexion{
	margin: 0 auto;
	max-width: 452px;
}
.formulaire h1{
	margin-bottom: 38px;
}
.modal{
	display: block;
	position: static;
}
.btns .popup-content{
	width: 277px !important;
}
.popup-content{
	width: auto !important;
}
.popup-content .formulaire{
  width: 435px;
  padding: 0 20px 0;
}
.mailing .popup-content .formulaire{
	width: inherit;
	background: inherit;
}
.formulaire legend{
	text-align: left;
	color: #d2d2d2;
}
.formulaire span{
	display:block;
}
.formulaire span.or:before{
	content: '';
	width:45%;
	border: 1px solid #ececec;
	display: inline-block;
	position: relative;
	top: -4px;
	left:-8px;
}
.formulaire span.or:after{
	content: '';
	width:45%;
	border: 1px solid #ececec;
	display: inline-block;
	position: relative;
	top: -4px;
	left:8px;
}
.intro span.error{
  text-align: left;
}
.content .bloc .top p{
 font-family: MontserratMedium, Arial, Helvetica, Sans-Serif;
 font-size: 24px;
}
.connectedPage .content .bloc .top p{
	font-size: 16px;
	font-weight: bold;
	color: #ffae02;
}
.dashboard{
  display: inline-block;
}
.dashboard.undermenu{
  float:right;

}
.blocArrondi,
.content .bloc .elt .title,
.mois a,
.content .childBloc .form-control,
.childBloc select,
.connectedPage.connected .content .apikeys button.bt2.first{
	border-radius: 5px;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	-o-border-radius: 5px;
}
.blocArrondi{
	background: #ffffff;
	border: solid 1px #d9d9d9;
	padding: 30px 0;
	clear: both;
}
.content .bloc .elt{
	float: none;
	overflow: hidden;	
	position: relative;
}
.content .bloc .elt .title{
	background: #006aa1;
	color: #ffffff;
	font-size: 13px;
	padding: 10px 20px;
	margin: 0px 15px;
}
.contact .blocArrondi,
.dashboard .contact .blocArrondi
{
	padding: 20px;
	max-width: 60%;
	width: 100%;
	margin: auto;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	-ms-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	-o-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	-moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.contact .blocArrondi hr,
.dashboard .contact .blocArrondi hr
{
	margin-bottom: 20px;
}
.contact p.blue{
	color: #006aa1;
}
.content .bloc .title:last-child{
	padding: 10px 0px;
}
.contact .blocCall{
	background: none;
	border: none;
}
.contact .callUs .numberCall,
.dashboard .contact .callUs .numberCall,
.contact .callUs .serviceCall,
.dashboard .contact .callUs .serviceCall
{
	display: table-cell;
	float: none;
	clear: both;
}
.contact .numberCall:after{
	position: absolute;
	left: 54px !important;
}
.contactForm .contact{
	width: inherit;
}
.content .elt.contactForm .contact a{
	background: inherit;
	color: #76b717;
}

.childBloc{
   float: left;
   width: calc(50% - 30px);
   margin: 0 15px;
   padding-top: 20px;
}
.content .bloc .childBloc .title{
	margin: 0 0 10px;
	background: #b3b3b3;
}

.dashboard li ul li{
  border: none;
  display: block;
  margin: 0;
}
.dashboard .popup-content{
	top: 43px!important;
	left: inherit!important;
	right: 0!important;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	padding: 5px 0 !important;
	-moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)!important;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)!important;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)!important;
}
.dashboard .popup-content li{
	border:none;
}

.contact,
.dashboard .popup-content .tarifs{
	width:430px;
}
.connected .btns.mobile .contact{
	width: inherit;
}
.dashboard .popup-content .tarifs.borderBot a{
	border-bottom: 1px solid #ffae02;
	padding-bottom: 16px;
	padding-top: 7px;
}
.dashboard .popup-content .tarifs.borderBot > a{
	border: none;
	padding-bottom:inherit;
	margin-top: inherit;
}
.contact p.contact a{
	padding: 5px 0px;
	margin: 0px;
}
.contact > a,
.dashboard .popup-content .tarifs > a{
	text-transform: uppercase;
	color: #686868;
	text-align: left;
	padding-left: 23px;
	display: block;
	font-size: 14px;
}
.frame{
	padding: 0 20px;
}
.contact p,
.dashboard .popup-content .contact p,
.dashboard .popup-content .tarifs p{
	margin: 0;
	padding-bottom: 2px;
	color: #686868;
	font-size: 14px;
	text-align: left;
	margin: 0 0 10px;
}
li .contact a,
.dashboard li .contact a
{
	padding: 11px 0;
}
li .blocArrondi,
.dashboard .contact .blocArrondi,
.dashboard li .blocArrondi
{
	border: none;
	box-shadow: inherit;
	-moz-box-shadow: inherit;
	-webkit-box-shadow: inherit;
	-o-box-shadow: inherit;
	-ms-box-shadow: inherit;
}
.contact a,
.dashboard .popup-content .tarifs a{
	width:93%;
	padding: inherit;
	margin-bottom: 6px;
}

.contact hr,
.dashboard .contact hr,
.dashboard .tarifs hr{
	border-bottom:none;
	width:inherit;
	margin:inherit;
	border-color: #ffae02;
	margin-top: 22px;
}
.contact label,
.dashboard .contact label,
.dashboard .tarifs label{
	display: block;
	text-align: left;
	padding:inherit;
	font-weight: bold;
	font-size: 14px;
}
.contact span,
.dashboard .contact span,
.contact form,
.dashboard .contact form,
.dashboard .tarifs span,
.dashboard .tarifs form{
	font-family: Montserrat, Arial, Helvetica, Sans-Serif; 
	color:#555555;
	font-size:13px;
	text-align: left;
}
.contact textarea,
.dashboard .contact textarea,
.contact input,
.dashboard .contact input,
.dashboard .tarifs textarea,
.dashboard .tarifs input{
	border: 1px solid #d7d7d7;
	padding: 0px 16px;
	width: 100%;
	border-radius: 4px;	
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	-o-border-radius: 4px;
	-ms-border-radius: 4px;
	margin-bottom: 8px;;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-ms-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	color:#555555;
}
.contact input.pull-right,
.dashboard .contact input.pull-right,
.dashboard .tarifs input.pull-right{
	width: 30%;
	color: #fff;
	text-transform: uppercase;
	border: none;
	float: right;
	margin: inherit;
}
.contact textarea:focus,
.contact input:focus,
.dashboard .tarifs textarea:focus,
.dashboard .tarifs input:focus{
	border-color: #006aa1;
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-ms-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.contact .blocNumber
.dashboard .contact .blocNumber{
	margin-bottom: 10px;
	position: relative;
}
.contact .callUs .numberCall,
.dashboard .contact .callUs .numberCall
{
	width:100%;
	color: #76b717;
	font-size: 39px;
	display:inline-block;
	vertical-align: top;
	text-align: center;
	font-weight: bold;
	margin-bottom: 0px;
	text-align: left;
	border: 2px solid #cecdcd;
	padding-left:15px;
	background: #ffffff;
	font-family: "Helvetica";
	border-right: 0;
	
}
.popup-content .contact .callUs .numberCall{
	font-size: 30px;
}
.popup-content .contact .callUs .blocNumber .serviceCall{
	font-size: 14px;
	padding-right: 2px;
	padding-top: 2px;
	padding-bottom: 2px;
}
.popup-content .contact .callUs .blocNumber .serviceCall:before{
	top: 10.2px;
}
.contact .callUs .serviceCall,
.dashboard .contact .callUs .serviceCall

{
	display: inline-block;
	font-weight: bold;
	margin-bottom: 0px;
	padding:0px 5px;
	color: #ffffff;
	background: #76b717;
	border: 1px solid #adadad;
	border-left: 1px solid #ffffff;
	line-height: inherit;	
	position: relative;
	right: 0;
	font-size: 18px;
	text-align: center;
	height: 100%;	
}

.contact .callUs .serviceCall:before,
.dashboard .contact .callUs .serviceCall:before

{
	content:"";
	display: inline-block;
	width: 23px;
	height: 38px;
	background: url(../images/icones/green-arrow.png) no-repeat;
	position: relative;
	position: absolute;
	left: 0px;
	top: 17.2px;
	background-size: 14px;
}
.contact .callUs > p,
.dashboard .contact .callUs > p
 {
	padding-top:10px;
}
.blocNumber{	
	display: flex;
	height: 62px;
}
.dashboard.undermenu li{
  position: relative;
}

.dashboard.undermenu li ul li a{
	width: 158px;
	display: block;
	border-bottom: solid 1px #d9d9d9;
	text-align: left;
	padding: 5px 20px;
    margin: 0 20px;
    font-size: 13px;
    letter-spacing: 0.05em;
	color: #b3b3b3;
}
.dashboard.undermenu li ul li a:hover{
	color: #4d4d4d;
	background: #e9e9f1;
}
.dashboard.undermenu ul .callUs a{
	margin: 0px;
}
.dashboard.undermenu li ul li:last-child a{
	border: none;
}
.dashboard.undermenu li .popup-arrow{
	transform: rotate(-134deg)!important;
	top: 0!important;
	left: inherit!important;
    right: 56px!important;
}
.content .bloc .undermenu ul{
  padding-bottom: 0;
}
.dashboard.undermenu li .nav-link{
	width: 116px;
}
.dashboard li .nav-link:Active {
    background: #006aa1;
    color: #ffffff;
}
.content.tabs{
  padding-top: 10px;
}
.content .bloc{
  padding-top: 0px;
}
.content .bloc.tabTitle{
  padding-top: 16px;
  width: 65%;
  display: inline-block;
}
.content .apikeys{
	width: 100% !important;
}
.content .apikeys strong{
	border-top: 1px solid #ccc;	
	display: block !important;
	padding: 15px 0;
}
.content .flex-container{
	display: flex;	
}
.flex-item{
	padding: 2em 2em;
	flex-basis: 50%;
}
.flex-item:first-child{
	padding-left: 0;
	border-right: 1px solid #ccc;	
	padding-bottom: 15px;
}
.connected h1{
  font-size: 16px;  
  font-weight: 700;  
  line-height: inherit;
}
.connected h2{
	font-size: 24px;
}
.content .bloc-tableau p{
  margin-left:28px;
}
.bloc-tableau{
	max-width: 721px;
}
.connected .bloc-tableau strong{
  color: #006aa1;
  font-size: 24px;
}
.bloc-tableau .top span,
.bloc-tableau .top span a{
  display: inline-block;
  width: 33%;
  font-size: 14px;
}
.bloc-tableau .top span:last-child{
	text-align: right;
	width: 34%;
}
.bloc-tableau label.custom-control-label{
	display:block !important;
}
.content .bloc-tableau label.custom-control-label{
	display:block;
}
.mois a{
    font-size: 16px;
    border: solid 1px #b3b3b3;
    padding: 10px 20px;
    display: block;
    color: #b3b3b3;
    margin-bottom: 10px;
   	background-image: url("../images/icones/eye.png");
	background-size: 20px 13px; 
	background-repeat: no-repeat;
	background-position: 98% center;
}
.mois a:hover{
	background-color: #006aa1;
	color: #ffffff;
	background-image: url("../images/icones/eye_blanc.png");
}
.text-right .btBlue{
   margin-right: 0;
}
.childBloc span.error{
	display: block;
}

.content .childBloc .form-control{
	padding: 16px 12px;
    font-size: 13px;
	font-family: Montserrat, Arial, Helvetica, Sans-Serif;
}
.w-33{
	width: 33%!important;
	float: left;
}
.w-61{
	width: 61%!important;
    float: right;
}
.labelShow{
	display: inline-block;
	width: 50%;
}
.childBloc select{
  	color: #b3b3b3;
	padding: 6px 12px 7px;
    font-size: 13px;
    outline: none;
    border: 1px solid #ced4da;
    width: 100%;
}
.childBloc .select-50{
	width: 50%;
	vertical-align: top;
}
.parametre label{
	display: block;
	padding-left: 25px;
	text-transform: uppercase;
	background-repeat: no-repeat;
	background-position: left center;
}
.blocArrondi .elt .childBloc label{
	line-height: 22px;
	padding-left: 0px;
}
.blocArrondi .elt .childBloc label i{
	float: left;
	margin-right: 5px;
	color: #006aa1
}
.parametre form{
	margin-bottom: 30px;
}
.parametre .bt{
	margin: 0;
}
h1.fl-l{
	font-size: 20px;
}
.content .bloc .top p.fl-r,
.content .bloc.parametre .top .fl-r,
.content .elt.tarifs .price p span,
.content .elt.tarifs .option strong,
.content .bloc .elt .title,
.contact > a,
.dashboard .popup-content .tarifs > a,
.connected .bloc-tableau strong,
.parametre label{
	font-family: MontserratBold, Arial, Helvetica, Sans-Serif;
}
.content .bloc .top p.fl-r{
 font-size: 16px;
 color: #006aa1;
 padding-bottom: 0;
 line-height: 39px;
}
.top .fl-r{ 
	text-align: right;
 }
.content .bloc.parametre .top .fl-r{
	font-size: 14px;
	color: #006aa1;
	line-height: 20px;
}
.parametre .fl-r a{
	display: block;
}
.content .bloc .top{
	overflow: hidden;
	padding: 15px 0;
}
.connectedPage.connected .content .apikeys .form-control{
	padding: 16px 12px;
	color: #000;
}
.connectedPage.connected .content .apikeys .input-group{
  	display: inherit;
}
.connectedPage.connected .content .apikeys .input-group .form-control{
  	width: 100%;
}
.connectedPage.connected .content .apikeys .input-group-append{
	position: absolute;
	height: 52px;
	right: 0px;
	top: 0px;
	z-index: 0;
}	
.connectedPage.connected .content .apikeys button.bt2.first{
	margin-top: 20px;
}
.connectedPage.connected .content .apikeys .input-group-append .btBlue{
	margin-right: 0px;
}
.connectedPage.connected .content .apikeys .bt:not(.btn-container .bt) {
	margin-left: 0px;
	width: 100%;
	margin-top: 7px;
}
.connectedPage.connected .nopad{
	padding: 0px;
}
.connectedPage.connected .blocArrondi.nopad{
	padding-top: 0px;
	padding-bottom: 30px;
}
.content .elt.tarifs{
	padding: 20px;
}
.content .elt.tarifs p,
.content .elt.tarifs span
{
	font-size: 14px;
	color: #646465;
}
.content .elt.tarifs .consumption p,
.content .elt.tarifs .consumption span
{
	color: #646465;
	display: block;
}
.content .elt.tarifs .price p{
	font-size: 14px;
	text-align: center;
	color: #646465;
}
.content .elt.tarifs .price p span{
	display: block;
	color: #006aa1;
}
.content .elt.tarifs a.border{
	padding-bottom: 10px;
}
.content .elt.tarifs .center{
	text-align: center;
}
.content .elt.tarifs .center.nav{
	display: inherit;
	padding-top: 20px;
}
.content .elt.tarifs .center .nav-item:first-child,
.content .elt.tarifs .center .nav-item:last-child,
.content .elt.tarifs .black{
	border-radius: 5px 0px 0px 5px;
	-webkit-border-radius: 5px 0px 0px 5px;
	-moz-border-radius: 5px 0px 0px 5px;
}
.content .elt.tarifs .center .nav-item:first-child{
	color: #ffffff;	
	display: inline-block;
	font-size: 13px;
	margin-top: 10px;
	font-family: helvetica;
	font-weight: bold;
	cursor: pointer;
}
.content .elt.tarifs .center .nav-item:last-child{
	color: #ffffff;	
	display: inline-block;
	font-size: 13px;
	margin-top: 10px;
	font-family: helvetica;
	font-weight: bold;
	cursor: pointer;	
}
.content .elt.tarifs .center .nav-item:last-child a:hover{
    background: #006aa1;
    border-color:#006aa1;
    color:#fff;
}
.content .elt.tarifs .black{
	background: #ffffff;
	color: #959595;
}
.content .elt.tarifs .border.black.active{
	background: #2e2f31;
	color: #ffffff;
	border: 1px solid #2e2f31 !important
}
.content .elt.tarifs .border.color{
	border-radius: 0px 5px 5px 0px;
	-webkit-border-radius: 0px 5px 5px 0px;
	-moz-border-radius: 0px 5px 5px 0px;
	color: #959595 ;
}
.content .elt.tarifs .border.color.active{
	background: #006aa1;
	color: #ffffff;
	border: 1px solid #006aa1 !important
}
.content .elt.tarifs .consumption{
	width: 100%;
	text-align: center;
	margin-top: 30px;
	background: #006aa1;
}
.content .elt.tarifs .consumption .nbCourrier{	
	width: 25%;
	border-right: 1px solid #dee2e6;
	border-left: 1px solid #dee2e6;
	padding-bottom: 0;
	display: inline-block;
	vertical-align: top;
	padding: 11.5px 0;
}
.content .elt.tarifs .consumption .nbCourrier.month p{
	font-weight: inherit;
}
.content .elt.tarifs .consumption .nbCourrier p{
	font-weight: bold;
	color: #ffffff;
	padding: 0;
}
.content .elt.tarifs .consumption.priority{
	margin-top: inherit;
	border-bottom: 1px solid #d9d9d9;
	background: #f6f6f6;
}
.content .elt.tarifs .consumption.priority .nbCourrier{
	padding-top: 26px;
	border-top: 1px solid #dee2e6;
	border-left: 1px solid #dee2e6;
	/*padding-bottom: 3em;*/
	height: 106px;
}	
.content .elt.tarifs .consumption.priority p{
	color: inherit;	
}
.content .elt.tarifs .consumption.priority p:not(.detail p){
	margin-top: 11px;
}
.content .elt.tarifs .consumption.priority .month{
	padding: 16px 0px;
	line-height: 0px;
}
.content .elt.tarifs .consumption.priority .month.marg{
	padding-top: 29px;
    padding-bottom: 32px;
}
.content .elt.tarifs .consumption .nbCourrier:last-child,
.content .elt.tarifs .consumption.priority .nbCourrier:last-child,
.content .elt.tarifs .consumption .nbCourrier.noborder{
	border-right: none;
}
.content .elt.tarifs .priority strong{
	display: block;
}
.content .elt.tarifs .option{
	width: 100%;
	padding: 0px 0px;
	background: #f6f6f6;
}
.content .elt.tarifs .option.fonction{
	background: none;
}
.content .elt.tarifs .option.fonction p{
	padding: 30px 0px;
}
.content .elt.tarifs .option strong{
	display: block;
	text-align: center;
	padding: 11.3px 0px;
	font-size: 13px;
	background: #006aa1;
	color: #ffffff;
	border-bottom: 1px solid #d9d9d9;
}
.content .elt.tarifs .option .nbPage{
	border-bottom: 1px solid #d9d9d9;
	border-right: 1px solid #d9d9d9;
	display: inline-block;
	width: 100%;
	padding: 11px 0px;
}

.content .elt.tarifs .option .nbPage.noborder{
	border-right: none;
}
.content .elt.tarifs .option .nbPage p,
.content .elt.tarifs .option .nbPage span
{
	padding-bottom: 0px;
	color: #646465;
}
.content .elt.tarifs .option .more{
	width: 25%;
	text-align: center;
}
.content .elt.tarifs .option .prix{
	width: 75%;
	vertical-align: top;
	padding: 11px 0;
	text-align: center;
}
.content .elt.tarifs .fonction{
	text-align: center;
	border-bottom: 1px solid #d9d9d9;
}
.content .elt.tarifs .fonction p,
.content .elt.tarifs .question p
{
	padding-bottom: 20px;
	color: #646465;
}
.content .elt.tarifs .question{
	text-align: center;
	/* padding: 30px 0px 0px 0px; */
}

.content .elt.tarifs .question p a{
	display: block;
}
.content .elt.tarifs .center .btn:last-child{
	background: none;
	color: #646465;
	border: 1px solid #d9d9d9;
}
.content .elt.tarifs .center .btn:last-child:hover{
    background:#b3b3b3;
    color: #fff;
}
.connectedPage .elt.tarifs .popup-content .formulaire{
	padding: 19px;
}
.connectedPage .elt.tarifs .popup-content .formulaire span.error{
  text-align: left;
  display: inherit;
  padding: 0px !important;
  padding-left: 20px !important;
  font-size: 12px !important;
  color: #a94442 !important;
  font-weight: inherit;
}
.content .elt.tarifs .formulaire span:last-child{
	border-radius : inherit;
	-moz-border-radius : inherit;
	-webkit-border-radius : inherit;
}
.connectedPage .elt.tarifs .formulaire button.btn{
	display: block;
	padding: 0 70px;
	margin: 14px auto 14px auto;
	height: inherit !important;
}

.content .elt.tarifs .formulaire span{
	width: 100%;
	padding: 0px;
}
.content .elt.tarifs .formulaire .bottom{
	padding-top: 4px;
}
.blocAccordion .rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow{
	border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 6px solid #006aa1;
    outline: none;
	right: 2px;
}
.blocAccordion .rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow{
	border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #006aa1;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header:hover,
.blocAccordion .rc-collapse > .rc-collapse-item-active > .rc-collapse-header {
    color:#006aa1;
}
.blocAccordion .rc-collapse-content > .rc-collapse-content-box{
	margin: 0;
	padding-top: 17px;
}
.blocAccordion :focus{
	outline: none;
}
.blocAccordion{
	padding-top: 50px;
}
.content .bloc .thead p{
	color: #ffffff;
	height: 60px;
}
.tableau{
	min-width: 280px;
	/*overflow-x: auto;*/
    clear: both;
    margin-bottom: 20px;
    position: relative;
    height: 100%;
    padding-top: 30px;
}

.case.tbody:hover{
	background: #e3f0f7!important;
}
.case{
	display: table;
}
.case.thead{
	background: #006aa1;
	border-radius: 2px;
}

.case.thead p:first-child{
	border-left: solid 1px #006aa1;
	border-radius: 5px 0 0px 0px;
	-moz-border-radius: 5px 0 0px 0px;
	-webkit-border-radius: 5px 0 0px 0px;

}
.case.thead p:last-child{
	border-radius: 0 5px 0px 0px;
	-moz-border-radius: 0 5px 0px 0px;
	-webkit-border-radius: 0 5px 0px 0px;
}
.case p,
.case .casex{
	padding: 6px 10px 7px;
	text-align: center;
	border-right: solid 1px #dddddd;
	display: table-cell;
	vertical-align: middle;
    
}
.casex{
    color: #4b4e53;
    font-size: 13px;
    line-height: 20px;
}
.case p:last-child{
	border-color: #006aa1;
	border:none;
}
.case.tbody{
	border-bottom: solid 1px #dddddd;
}
.case.tbody i.material-icons-outlined{
	color: #000000;
	font-weight: inherit;
	opacity: 0.54;
	margin-top: 10px;
}
.case.tbody i:hover{
	opacity: 0.87;
}
.casex.case3 img{
	display: block;
	margin: 0 auto;
}
.case.tbody .casex:last-child{
	border-color: #dddddd;
}
.case.tbody .casex:first-child{
	border-left: solid 1px #dddddd;
}
.case.tbody .casex{
	word-break: break-word;
}
.case1{
	max-width: 110px;
    min-width: 110px;
}
.case2{
	max-width: 92px;
    min-width: 92px;
}
.case3{
	max-width: 108px;
    min-width: 108px;
}
.case4{
	max-width: 173px;
    min-width: 173px;
}
.case5{
	max-width: 104px;
    min-width: 104px;
}
.case6{
	max-width: 70px;
    min-width: 70px;
}
.case7{
	max-width: 190px;
    min-width: 190px;
}
.unitaire .case7{
	max-width: 124px;
    min-width: 124px;
}
.case8{
	max-width: 100px;
    min-width: 100px;
}
.case9,
.case10,
.case12{
	max-width: 72px;
    min-width: 72px;
}
.case11{
	max-width: 64px;
    min-width: 64px;
}
.fullcase{
	text-align: center;
	border: solid 1px #b3b3b3;
	border-radius: 5px ;
	-moz-border-radius: 5px ;
	-webkit-border-radius: 5px ;
	margin-bottom: 20px;
}
.content .bloc .fullcase p{
	width: 100%;
	padding: 10px 15px;
}
.case7 img,
.mailing .case4 img{
    margin: 0 3px;
 }
.mailing .case3 {
    max-width: 100px;
    min-width: 100px;
}
.mailing .case6 {
    max-width: 160px;
    min-width: 160px;
}
.mailing .case8 {
    max-width: 130px;
    min-width: 130px;
}
.mailing .case9 {
    max-width: 112px;
    min-width: 112px;
}
.mailing .case10 {
    max-width: 164px;
    min-width: 164px;
}
 .tableau .popup-content {
    max-width: 165px !important;
    background: black!important;
    color: #ffffff;
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)!important;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)!important;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175)!important;
}
.connected .tableau .case7 .popup-content,
.connected .tableau .case6 .popup-content
{
	max-width: 176px !important;
	top: 0px;
	left: 549px;
}
.mailing .tableau .case3 .popup-content{
	width: 250px !important;
}
.tableau .popup-arrow {
    background-color: #000000!important;
}
.checkbox .custom-control{
    display: table;
}
.checkbox.fl-r{
	width: 100%;
}
.childBloc.fl-r{
	margin: 0;
	width: 100%;
}
.childBloc.fl-r .labelShow{
    text-align: right;
    padding-right: 15px;
    width: auto;
    color: #b3b3b3;
    font-size: 13px;
    padding-top: 7px;
    font-size: 13px;
}
.childBloc .select-w70{
	width: 70px;
	vertical-align: top;
}
.checkbox.fl-r .custom-control,
.childBloc.fl-r .form-group{
	float: right;
}
.childBloc.fl-r p{
	float: right;
	padding-top: 7px;
	padding-left: 10px;
	font-size: 12px;
}
.checkbox label{
	color: #b3b3b3;
	padding-top: 0;
}
.BlocPagination{
	text-align: center
}
.content .BlocPagination li{
	font-size: 13px;
	margin: 0;
	height: 34px;
	border-radius: 0;	
}
.rc-pagination{
	display: inline-block;
}
.rc-pagination a{
	border: solid 1px #cccccc;
	color: #b3b3b3;
	background-repeat: no-repeat;
	display: block;
    height: 34px;
	line-height: 34px;
	width: 34px;
	border-right:none;

}

.rc-pagination .rc-pagination-next, .rc-pagination .rc-pagination-prev{
 height: 34px;
}
.rc-pagination .rc-pagination-item-active a,
.rc-pagination .rc-pagination-item-active a:hover{
	background: #006aa1;
	color: #ffffff!important;
	border-color: #006aa1;
	border:none;
}
.rc-pagination-item,
.rc-pagination .rc-pagination-prev{
	margin-right: 0px!important;
}
.rc-pagination-item,
 .rc-pagination .rc-pagination-prev,
 .rc-pagination .rc-pagination-next,
 .rc-pagination-item-active a
 {
	border-radius:0!important;
}
.rc-pagination .rc-pagination-prev{
	background-image: url("../images/icones/navigate_before.svg");
	background-size: 28px 30px;
	background-position: center;
}
.rc-pagination-jump-prev:hover,.rc-pagination-jump-next:hover{
	background-color: #006aa1;
}
.rc-pagination-jump-prev:after,.rc-pagination-jump-next:after{
	margin-top: -29px !important;
}
.rc-pagination a:hover{
	color: #fff !important;
	background: #006aa1
}
body .rc-pagination-item{
    border:none;
}
body .rc-pagination-item-active{
	border:none;
	background-color:inherit;
}
.rc-pagination-item-active a{
	color: #ffffff;
}
.rc-pagination .rc-pagination-next,
.rc-pagination .rc-pagination-prev{
	background-repeat: no-repeat;
	overflow: hidden;
    text-indent: -58px;
}
.rc-pagination .rc-pagination-next{
	background-image: url("../images/icones/navigate_next.svg");
	background-size: 28px 30px;
	background-position:center;
	border-radius: 0 5px 5px 0!important;
}
.rc-pagination .rc-pagination-prev:hover{
	 background-image: url("../images/icones/navigate_before.svg");
	 background-size: 28px 30px;
	 color:#006aa1;
	 background-color: #d9d9d9;
}
.rc-pagination .rc-pagination-next:hover{
	 background-image: url("../images/icones/navigate_next.svg");
	 background-color: #d9d9d9;
}
.rc-pagination .rc-pagination-prev{
	border-radius: 5px 0 0 5px!important;
	border-right:none;
}


.rc-pagination span{
	display: inline-block;
	margin-right: 5px;
}
.legende{
	clear: both;
}

.legende span{
	background-repeat: no-repeat;
    display: inline-block;
    margin-bottom: 20px;
    color: #b3b3b3;
    font-size: 12px;
    font-weight: bold;
    margin-right: 30px;
}
.impression,
.pli,
.affranchissement,
.courrier,
.annule{
	padding-left: 25px;
	background-position: left center;
	background-size: 20px 19px;
}
.legende i{
	float: left;
	padding-right: 5px;
	padding-top: 2px;
	color: #000000;
	opacity: 0.54;
}
.tableau .line:nth-child(even){
	background: #eeeeee;
	display: table-cell;
}
.attente{	
    background-size: 16px 19px;
    background-position: left center;
    padding-left: 21px;
}
.annule{	
	background-size: 20px 20px;
}
.partner{
	text-align: center;
}
.partner h2
{
	text-align: center;
	border-top: solid 2px #f2f2f2;
	padding-top: 20px;
	color: #686868;
	font-size: 16px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.15em;
	line-height: inherit;
}
.partner a{
	display: block;
	margin-bottom: 35px;
}
.partner a{
	width: 25%;
	display: inline-block;
	vertical-align: top;
	height: 90px;
	line-height: 90px;
}

.connectedPage .apikeys .floatNone{
	float: none;
	clear: both;
}
.connectedPage.connected h1{
    font-size: 16px;	
	font-weight: bold;
	line-height: inherit;
}
.connectedPage.connected h2{
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
	display: inline-block;
	line-height: inherit;	
}
.connectedPage.connected .typeCompte .childBloc{
	float: right;
	text-align: right;
}
.connectedPage.connected .typeCompte .childBloc span{
	display: block;
	text-transform: uppercase;
	font-size: 12px;
	color: #686868; 
}
.connectedPage.connected .blocArrondi.margin{
	margin-bottom: 75px;
}
span.legend{
	text-decoration: underline;
}
.pagination{
	display: inline-block;
    float: none;
    width: 100%;
    text-align: center;
    margin: 30px 0;
}
.partner a {
    width: 25%;
}
.menuEnvois{
	position: relative;
}
.menuEnvois .popup-arrow{
	display: none;
}
.dashboard li.menuEnvois.disabled a.nav-link:after{
	border-top: 6px solid #b3b3b3;
}
.dashboard li.menuEnvois a.nav-link:after,
.connected .dashboard li.menuEnvois.disabled a.nav-link:after{
	content: " ";
	border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #006aa1;
    position: relative;
    top: 10px;
    margin-left: 5px;
}
.dashboard li.menuEnvois a.nav-link:hover:after,
.connected .dashboard li.menuEnvois.disabled a.nav-link:hover:after{
	border-top: 6px solid #ffffff;
}
.dashboard li.menuEnvois .popup-content a{
	display: block;
	text-align: left;
	padding: 6px 12px;
	border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    font-size: 12px;
    color: #b3b3b3;
    letter-spacing: 1px;
}
.dashboard li.menuEnvois .popup-content a:first-child{
	border-bottom: solid 1px #b3b3b3;
}
.dashboard li.menuEnvois .popup-content a:hover{
	background: #e9e9f1;
	color: #000000;
}
.dashboard .menuEnvois .popup-content{
  left: 0!important;
  border-color: #006aa1;
  -moz-border-radius: 5px!important;
  -webkit-border-radius: 5px!important;
  -khtml-border-radius: 5px!important;
  padding: 0!important;
}
.connected .tableau .text-center{
	position: absolute;
	width: 1068px;
	line-height: 200px;
	height: 95.1%;
	display: table-cell;
	vertical-align: middle;
	background: rgba(0,0,0,0.3);
	z-index: 4;
	text-align: center;

}
.connected .tableau .text-center .text-info{
	position: absolute;
	top: 50%;
	margin:auto;
	left: 50%;

}
.tableau .case .casex{
	font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: 14px;
}
.hide-line{
	display: none;
}
.case.tbody:hover .hide-line{
	display: block;
	border: 1px solid #ddd;
	border-bottom: none;
}
.hide-line p{
	vertical-align: top;
}
.hide-line .first-item{
	display: flex;
	width: 217px;
	border-right: 1px solid #ddd;
	justify-content: center;
	order: 1;
}
.hide-line .second-item{
	display: flex;
	width: 643px;
	border-right: 1px solid #ddd;
	justify-content: left;
	order: 2;
}
.hide-line .third-item{
	display: flex;
	border: none;
	width: 137px;
	justify-content: center;
}
.hide-line .first-item p, .hide-line .second-item p, .hide-line .third-item{
	border: none;
}
.hide-line strong{
	color: #006aa1;
}
.hide-line a{
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    padding-bottom: 3px;
}
.hide-line a span{
	color: #faac18;
	font-size: 13px;
}
.hide-line a span img{
	margin-right: 5px;
    margin-bottom: 2px;
}
.hide-line span{
	color: #343a40
}
.line .casex.case2 i{
	font-size: 20px;
	color: #2aa4e5;
	opacity: 1;
}
.line .arScan-content, .line .arScan-arrow{
	background: #2aa4e5 !important;	
}
.line .arScan-content strong{
	color: #fff;
}
.ArScanLink img{
	margin-left: 13px;
}
.ArScan-help{
	margin-left: 3px;
	font-size: 20px !important;
	color: #2aa4e5 !important;
	opacity: 1 !important;
}
.mb-block.name.item-flex{
	display: flex;
}
a.link-to-post{
	margin-top: 25px;
}
.spinner-border{
	margin-bottom: 20px;
}
.popup-content .hideSuccess,
.hideSuccess
{
	display: none;
}
.hideSuccess.showSuccess,
.hideSuccess.showSuccess,
.succefull.hideSuccess.showSuccess,
.succefull.hideSuccess.showSuccess
{
	display: block;
	font-size: 12px !important;
    color: #12b47d !important;
    text-align: center;
    font-family: Montserrat, Arial, Helvetica, Sans-Serif;
    margin-bottom: 15px;
}
.home .hideSuccess.showSuccess{
	margin-bottom: inherit;
}
.contact .hideSuccess.showSuccess{
	font-size: 14px !important;
	text-align: left;
}

.succefull.hideSuccess.showSuccess{
	margin-top: 15px;
}
.case.tbody.archive{
	background: #ffe7e7;
}
.connected .tableau .popup-content{
	max-width: 73px;
	width: 100% !important;
	font-size: 11px;
}
.connected .tabTitle.apikeys .text-align{
	padding: 30px;
}
.connected .tabTitle.apikeys .text-align p{
  	padding: 0px;
  	font-size: 17px;
  	color: #797979;
}
.connected .tabTitle.apikeys .text-align hr{
	margin: 0px;
	border-bottom: inherit;
	margin-top: 15px;
	margin-bottom: 15px;
}
.connected .tabTitle.apikeys .text-align .confirm{
	font-size: 12px;
	max-width: 350px;
	width: 100%;
	text-align: left;
}
.connected .tabTitle.apikeys .text-align .btn-container{
	text-align: right;
}
.connected .tabTitle.apikeys .text-align .non{
	background: none;
	color: #9b9b9b;
	border: 1px solid;
}
.connected .tabTitle.apikeys .text-align .non:hover{
	background: #006aa1;
	border-color: #006aa1;
	color: #ffffff;
}
.connected .tabTitle.apikeys .text-align .oui:hover{
	background: #fd2525;
}
.connected .tabTitle.apikeys .text-align .oui{
	background: #ee5656;
}
.connected .tabTitle.apikeys .copied{
	display: inline-block;
	color: #79c949;
	font-size: 13px;
	padding-top: 4px;
	vertical-align: top;
	text-align: center;
	width: 100%;
}
.connected .tabTitle.apikeys .copied:before{
	content: '';
	display: inline-block;
	width: 17px;
	height: 17px;
	background: url('../images/icones/trust.png') no-repeat;
	background-size: 17px;
	top: 6px;
	position: relative;
	margin-right: 6px;
}
.childBloc.center{
	width: 58%;
	min-width: 400px;
	margin: 0 auto;
	float: none;
}
.btYellow{
	background: #fab633;
}
.content .bloc .elt .title.identite{
	background-image: url("../images/icones/identite_societe.png");
	background-repeat: no-repeat;
	background-size: 36px 33px;
	padding-left: 54px;
    background-position: 12px center;
}
.content .bloc .elt .title.signataire{
	background-image: url("../images/icones/signature.png");
	background-repeat: no-repeat;
	background-size: 30px 27px;
	padding-left: 52px;
    background-position: 12px center;
}
.content .bloc .elt .title.information{
	background-image: url("../images/icones/info_paiement.png");
	background-repeat: no-repeat;
	background-size: 24px 27px;
	padding-left: 46px;
    background-position: 12px center;
}
.btn.btYellow,
.btn.btYellow:hover{
	background-image: url("../images/icones/check_b.png");
	background-repeat: no-repeat;
	background-size: 17px 14px;
    padding-left: 46px;
    background-position: 19px center;
    max-width: inherit;
}
.content .bloc p.pl-15{
	font-size: 12px;
}
.apikeys .relative{
	position: relative;
}
.apikeys .text-center
{
	text-align: center !important;
	position: absolute;
	right: -30px;
	top: 0px;
}
.blocTarifs h1{
	font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .15em;
    line-height: inherit;
}
.user.hidden,
.msgSuccess.hideMsg{
 display: none;
}
.blocArrondi.unitaire .btBlue{
	margin: 0px;
}
.elt.factures{
	position: relative;
}
.factures .text-center{
	position: absolute;
	width: 94.7%;
	height: 100%;
	background: rgba(0,0,0,0.1);
	border-radius: 5px;
  	-o-border-radius: 5px;
  	-webkit-border-radius: 5px;
  	-moz-border-radius: 5px;
  	top: 0px;

}
.factures .text-center .text-info{
	position: relative;
	top: 51%;
}

.tableau .popText .popup-content,
.tableau.mailing .popup-content:first-child
{
	background: #ffffff !important;
	max-width: 209px;
	color: #b3b3b3;
}
.popText .popup-content .formulaire{
  width: 100%;
}
.popText .popup-overlay {
	background: rgba(0, 0, 0, 0.1)!important;
}
.tableau .popText .popup-content {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.02)!important;
    -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.02)!important;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.02)!important;
}
.connectedPage .bloc.noborder.modal .bt{
	display: block;
	margin:auto;
	margin-top: 10px;
    margin-bottom: 10px;
}
.connectedPage .parametre input[name="email"]{
	background: #ebebeb;
	color: #7d7d7d;
}
.connectedPage .parametre .form-group{
	position: relative;
}
.connectedPage .parametre .childBloc:nth-child(2) form:first-child .icon{
	position: absolute;
	top: 11px;
	right: 5px;
	width: 24px;
	height: 24px;
	background: url('../images/profil/text-gray.png') no-repeat;
	background-size: 18px auto;

}
.connectedPage .parametre .childBloc:nth-child(2) form:last-child .icon{
	position: absolute;
	top: 11px;
	right: 7px;
	width: 24px;
	height: 24px;
	background: url('../images/profil/enveloppe-gray.png') no-repeat;
	background-size: 18px auto;
}
.connectedPage .parametre .childBloc:nth-child(3) form:first-child .icon{
	position: absolute;
	top: 4px;
	right: 5px;
	width: 24px;
	height: 24px;
	background: url('../images/profil/phone-gray.png') no-repeat;
	background-size: 16px auto;	
}

.connectedPage .parametre .childBloc:nth-child(3) form:last-child .icon{
	position: absolute;
	top: 8px;
	right: 7px;
	width: 24px;
	height: 24px;
	background: url('../images/profil/password-gray.png') no-repeat;
	background-size: 16px auto;
}
.connexion.formulaire .bt{
	margin-right: 0px;
	margin-left: 0px;
}
.formulaire .text-center.isLoader{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.2);
}
.formulaire .text-center.isLoader .spinner-border{
	position: relative;
	top: 45.2%;
}
.formulaire .text-center.isLoader .spinner-border.text-info{
	color: #ffffff !important;
}
.content .elt.tarifs span.call,
.content .elt .text-center.bottom span.or{
	font-size: 12px;
}
.blocAccordion .elt .center h2, .tarifs h2, .contact-page h2{
	font-size: 24px !important;
}
.contact-page strong:not(p.blue strong, .liens_footer div strong){
	color: #ffae02;
}
.content h2{
	font-size: 34px;
	padding: 15px 0;
}
.content .partner h2{
	font-size: inherit;
}
.loadParent{
	position: relative;
}
.connectedPage .loadParent .loader{
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	background: rgba(1,1,1,0.2);
}
.connectedPage .loadParent .loader .text-info{
	position: relative;
	top: 50%;
}

.content .bloc.bg .api{
	/*background: url("../images/icones/autonomie.png") center left  no-repeat;
    background-size: 32px 43px;
    padding: 10px 0 10px 42px;*/
}
.support{
	/*background: url("../images/icones/support.png") center left  no-repeat;
	background-size: 50px 42px;
    padding: 10px 0 10px 63px;*/
}
.souple{
	/*background: url("../images/icones/247.png") center left  no-repeat;
	background-size: 50px 42px;
    padding: 10px 0 10px 63px;*/
}
.content .bloc.bg h3{
	color: #006aa1;
}
.content .bloc.bg{
    padding: 30px 30px 10px;
    margin-bottom: 30px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    /*background: #e7eeefcf;*/
    background: #efeff5;
}
.content .bloc.bg .popup-content .formulaire{
	padding-top: 5px;
}
.content .elt.tarifs .consumption.option .nbCourrier p{
   color: #646465;
   font-weight: normal;
}
.content .elt.tarifs .consumption.option .nbCourrier{
	border-bottom: solid 1px #dee2e6;
}
.content .elt.tarifs .consumption span.titl{
	font-size: 12px;
	color: #ffffff;
}
.content .elt.tarifs .consumption.noMarge{
	margin: 0;
}
.content .elt.tarifs .consumption .nbCourrier.W75{
	width: 75%;
	border-top: solid 1px #ffffff;
}
.content .elt.tarifs .consumption .nbCourrier.borderLeft{
   border-left: solid 1px #ffffff;
}
.content .elt.tarifs .consumption.priority .nbCourrier{
	position: relative;
}

.content .elt.tarifs .consumption.priority .nbCourrier .detail p{
	font-size: 12px;
}
.content .elt.tarifs .popup-content{
	border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -khtml-border-radius: 0px !important;
    left: 0!important;
    right: 0!important;
    top:0!important;
}
.content .elt.tarifs .popup-content .detail{
	padding: 10px;
}

.content .elt.tarifs .popup-content .detail p{
	padding: 0;
	font-size: 12px;
}

.content.relativeLoader .loader{
	position: absolute;
	top: 0px;
	height: 100%;
	width: 100%;
	left: 0px;
	background: rgba(0,0,0,0.2);
}
.content.relativeLoader .text-info{
	position: relative;
	top: 47%;
}
#menu .material-icons-outlined{
	color: #006aa1;
	display: inline!important;
	border: none;
	margin: 0 10px 0 0px;
    position: relative;
    top: 6px;
}
.mobVersion{
	display: none;
	width: 100%;
}

.content .form-control{
  font-family: Montserrat, Arial, Helvetica, Sans-Serif;
}
.italic{
	font-size: 13px !important;
	font-style: italic;
	margin-top: 15px;
}
.tableEcosysteme{
	padding-top: 15px;
}
.grid-elt{
	display: grid;
	grid-template-columns: 80px 1fr;
	grid-gap: 15px;
	margin-bottom: 20px;
}
.left{
	place-self: center baseline;
}
.grid-elt h4{
	margin-bottom: 0;
}
.grid-elt a{
	font-size: 14px;
}
.grid-elt span{
	color: inherit;
	font-weight: bold;
	text-decoration: underline;
}
.a2
{
	border-radius: 10px;
	padding:4px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: lightblue;
}

.hr2
{
	margin:0px;
	margin-top: 25px;
	margin-bottom: 20px;
}
.rc-collapse-content-box a{
	font-size: 14px;@font
	font-weight: normal !important;
	text-decoration: underline !important;
}
.eco-price{
	padding:5px;
	color:#078c00;
	background-color:rgb(73 195 150 / 13%);
	font-family: sans-serif;
	font-weight: bold;
	border-radius: 5px;
}
.icon-letter img{
	width: 46px;
  	margin-bottom: 5px;
}
