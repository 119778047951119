@charset "UTF-8";
.form-control::placeholder{
 color: #b3b3b3;
}

label{
	padding-left: 20px;
}
.radio label{
	padding-left: 2px;
    padding-top: 5px;
}
form.radio{
	text-align: right;
}
canvas{
	height: 100%;
}
.infoChart{
	background: #303030;
	padding: 20px 25px 0;
	text-transform: uppercase;
}
.infoChart span{
    font-family: MontserratBold, Arial, Helvetica, Sans-Serif;
    color: #ffffff;
}
.refresh{
	background: url("../images/icones/refresh.png");
	background-repeat: no-repeat;
	background-size: 15px 15px;
    width: 15px;
    height: 15px;
    display: inline-block;
    float: right;
}
.connected.connectedPage .bloc-tableau .radio, .connected.connectedPage .displayNone{
	display: none;
}
.custom-control-input:checked~.custom-control-label::before{
	border-color: #27aae2;
    background-color: #27aae2;
    box-shadow: none;
}
.custom-control-label::before, .custom-control-label::after{    
	display: none;
}
.connectedPage .aside .custom-control-label::before{
	top: 0;
}
.connectedPage .aside .custom-control-label::after{
	top:2px;
}
.btn{
	max-width: 208px;
    width: 100%;
}
.connexion .bottom{
	padding-top: 20px;
	font-size: 12px;
	margin-bottom: 30px;
	line-height: 21px;
}
.connexion .bottom button{
	margin-bottom: 14px;
}
/*** tabs ***/
.content .communtab h2{
	margin-bottom: 30px;
}
.content .communtab h1,
.content .communtab h3{
    line-height: 30px;
    margin-bottom: 20px;
    font-size: 24px;
}
.content .communtab h1{
	font-size: 24px;
	margin-bottom: 30px;
	margin-top: 6px;
}
.communtab .nav-link{
  color: #b3b3b3;
  font-size: 18px;
  background-color: inherit;
  font-family: MontserratSemiBold, Arial, Helvetica, Sans-Serif;
}
.communtab .nav-link.active{
  color: #006aa1;
  background-color: inherit;
}
.communtab .nav-link{
	background-repeat: no-repeat;
	background-size: 24px auto;
	padding: 4px 0 0 35px;
}
#left-tabs-tab-first{
	/*background: url("../images/icones/tableau_g.png") scroll left center ;*/
	background-repeat: no-repeat;
	background-size: 24px auto;
}
#left-tabs-tab-first.active{
	/*background: url("../images/icones/tableau_b.png") scroll left center ;*/
	background-repeat: no-repeat;
	background-size: 24px auto;
}
#left-tabs-tab-second{
	/*background: url("../images/icones/profil_g.png") scroll left center ;*/
	background-repeat: no-repeat;
	background-size: 24px auto;
}
#left-tabs-tab-second.active{
	/*background: url("../images/icones/profil_b.png") scroll left center ;*/
	background-repeat: no-repeat;
	background-size: 24px auto;
}

#left-tabs-tab-third{
	/*background: url("../images/icones/key_g.png") scroll left center ;*/
	background-repeat: no-repeat;
	background-size: 24px auto;
}
#left-tabs-tab-third.active{
	/*background: url("../images/icones/key_b.png") scroll left center ;*/
	background-repeat: no-repeat;
	background-size: 24px auto;
}
#left-tabs-tab-fourth{
	/*background: url("../images/icones/facture_g.png") scroll left center ;*/
	background-repeat: no-repeat;
	background-size: 24px auto;
}
#left-tabs-tab-fourth.active{
	/*background: url("../images/icones/facture_b.png") scroll left center ;*/
	background-repeat: no-repeat;
	background-size: 24px auto;
}
.communtab .nav-item{
  margin-bottom: 24px;
}


.leftTab{
	width: 100%;
}
.rightTab{
	float: left;
	/*border-left: solid 1px #b3b3b3;*/
	width: calc(100% - 253px);
	margin-bottom: 40px;
	min-height: 400px;
}
.rightTab .bloc{
	padding-top: 0px;
	padding-left: 70px;
	margin: -1px 0 80px 0;
}
.content .form-control,
.content button{
  box-shadow: none!important;
  font-size: 12px;
}
.content .form-control:focus{
  border-color: #006aa1;
  color: #006aa1;
}
.content .input-group{
  margin-bottom: 22px;
}
.content .input-group .form-control{
  padding: 16px 20px;
  height: auto;
  border-radius: 8px 0px 0px 8px;
  -moz-border-radius: 8px 0px 0px 8px;
  -webkit-border-radius: 8px 0px 0px 8px;
  -khtml-border-radius: 8px 0px 0px 8px;
  color: #b3b3b3;

}
.content .apikeys .input-group .form-control{
   border-radius: 8px 10px 10px 8px;
  -moz-border-radius: 8px 10px 10px 8px;
  -webkit-border-radius: 8px 10px 10px 8px;
  -khtml-border-radius: 8px 10px 10px 8px;
}
.content .input-group button{
  font-family: MontserratSemiBold, Arial, Helvetica, Sans-Serif;
   border-radius: 0 8px 8px 0;
  -moz-border-radius: 0 8px 8px 0;
  -webkit-border-radius: 0 8px 8px 0;
  -khtml-border-radius: 0 8px 8px 0;
  padding-left: 25px;
  padding-right: 25px;
}
.form-control:disabled, .form-control[readonly]{
	background: #ffffff;
}
.form-control.is-invalid,
.contact .form-control.is-invalid{
	border-color: #a94442;
	background: none;
}

.form-control.is-invalid::placeholder{
 color: #a94442;
}

span.error, .content .elt.tarifs span.error
{
    font-size: 12px;
    padding-left: 13px;
    color: #a94442;
    text-align: left;
}
.contact span.error{
	color: #a94442;
	padding-left: 17px;
}
.fieldGroup{
	position: relative;
}
.fieldGroup button{
	position: absolute;
	top: 0px;
	right: 0px;
	margin-right: 0px;
	font-family: MontserratSemiBold, Arial, Helvetica, Sans-Serif;
    border-radius: 0 8px 8px 0;
    -moz-border-radius: 0 8px 8px 0;
    -webkit-border-radius: 0 8px 8px 0;
    -khtml-border-radius: 0 8px 8px 0;
    padding-left: 25px;
    padding-right: 25px;
    height: 52px;
}
.fieldGroup button.react-datepicker__navigation{
	top: 10px;
	height: 10px;
	border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    -khtml-border-radius: 0;

}
.nav-link .dropdown-menu{
	padding: 0;
	min-width: 124px;
}
.connected .dashboard li .nav-link.dropdown{
	padding: 0;
	border: none;
}
.nav-link .btn-primary{
  color: #006aa1;
  text-transform: uppercase;
  background: none;
  font-family: Montserrat, Arial, Helvetica, Sans-Serif;
  font-size: 13px;
  font-weight: bold;
  padding: 0;
  border: none;
  vertical-align: top;
  padding: 6px 12px;
  height: 40px;
  line-height: 26px;
}
.nav-link:hover .btn-primary{
	color: #ffffff;
}
.nav-link.show>.btn-primary.dropdown-toggle,
.nav-link.show>.btn-primary.dropdown-toggle:focus,
.nav-link.show>.btn-primary.dropdown-toggle:hover{
  background: #006aa1;
  border-color: #29abe2;
}
.nav-link .dropdown-item:first-child{
  border-bottom: 1px solid #d9d9d9; 
}
.nav-link .dropdown-item{
	color: #b3b3b3;
	padding: 2px 11px;
}
.dashboard .nav-link .dropdown-menu{
	padding: 0px 0px;
}
.dashboard .nav-link .dropdown-item{
	padding: 0px 0px;
}
.dashboard .nav-link .dropdown-item:first-child{
	border: none;
}

.dashboard .nav-link .dropdown-item a{
	padding: inherit;
	width: 100%;
	display: block;
	padding: 0px 26px;
	border-bottom: 1px solid #d9d9d9;
	text-transform: capitalize;
	color: #b3b3b3;
}
.dashboard .nav-link .dropdown-item a:hover{
	background: #dff1f7;
	color: #4d4d4d;
}
.nav-link .dropdown-item:hover{
	color: #29abe2;
}
.nav-link .dropdown-toggle::after {
    border-top: 0.4em solid;
    border-right: .35em solid transparent;
    border-bottom: 0;
    border-left: .35em solid transparent;
}
.search,
.search:hover{
	background-image: url("../images/icones/loupe.png");
	background-repeat: no-repeat;
	background-size: 14px 15px;
	background-position: center;
}
.fieldGroup input[type="text"]{
    line-height: 34px;
    height: 34px;
    padding: 0 15px;
}
.fieldGroup button{
	height: 34px;
	padding: 0 15px;
}
.fieldGroup .react-datepicker-wrapper{
	width: 100%;
}
.fieldGroup .react-datepicker__input-container{
    width: 100%;
}
.fieldGroup .react-datepicker__input-container input[type="text"]{
 	width: 99.3%;
 	background-position: calc(100% - 40px) center;
}
.checkbox label{
	display: inline-block;
	outline: none;
	line-height: 23px;
}
.custom-control i{
	float: left;
	color: #000000;
	opacity: 0.54;
	margin-right: 5px;
}
.checkbox input[type="checkbox"]{
	
}
.checkbox .custom-control-input:focus ~ .custom-control-label::before{
	box-shadow: none;
	display: none !important;
}
.plr-30 .btBlue{
	margin: 0 0 30px;
	text-transform: uppercase;
}
.content .bloc .plr-30 .elt{
	padding-left: 20px;
	padding-right: 20px; 
	overflow: visible;
}
@media only screen and (max-width: 670px) {
	.leftTab{
		float: none;
		width: 100%;
		margin: 0 auto 50px;
		text-align: center;
		border-bottom: solid 1px #b3b3b3;
	}
	.rightTab{
       float: none;
       border:none;
       width: 100%;

	} 
	.rightTab .bloc{
		padding-left: 0;
		margin: 0 auto;
	}
	.communtab .nav-item{
		display: inline-block;
	}
	.leftTab .flex-column{
      flex-direction: inherit!important;
      display: inherit;
	}
	#left-tabs-tab-first{
	/*background: url("../images/icones/tableau_g.png") scroll center 0 ;*/
	background-repeat: no-repeat;
	background-size: 60px auto;
	
   }
   #left-tabs-tab-first.active{
	/*background: url("../images/icones/tableau_b.png") scroll center 0 ;*/
	background-repeat: no-repeat;
	background-size: 60px auto;
	
   }
   #left-tabs-tab-second{
	/*background: url("../images/icones/profil_g.png") scroll center 0 ;*/
	background-repeat: no-repeat;
	background-size: 60px auto;
	
   }
   #left-tabs-tab-second.active{
	/*background: url("../images/icones/profil_b.png") scroll center 0 ;*/
	background-repeat: no-repeat;
	background-size: 60px auto;
	
   }
   #left-tabs-tab-third{
	/*background: url("../images/icones/key_g.png") scroll center 0 ;*/
	background-repeat: no-repeat;
	background-size: 60px auto;
	
}
#left-tabs-tab-third.active{
	/*background: url("../images/icones/key_b.png") scroll center 0 ;*/
	background-repeat: no-repeat;
	background-size: 60px auto;
	
}
#left-tabs-tab-fourth{
	background: url("../images/icones/facture_g.png") scroll center 0 ;
	background-repeat: no-repeat;
	background-size: 60px auto;
	
}
#left-tabs-tab-fourth.active{
	background: url("../images/icones/facture_b.png") scroll center 0 ;
	background-repeat: no-repeat;
	background-size: 60px auto;
}
   .communtab .nav-link{
	background-size: 24px auto;
	padding-top: 60px;
	padding-left: 0;
	width: 70px;
	height: 56px;
	overflow: hidden;
  }
  .nav-item{
  	padding: 0 1%;
  }
}
