@charset "UTF-8";
.footer .top{
  border-top: solid 2px #e8e8e8;
  background: #e8e8e8;
  padding-top: 30px;
  padding-bottom: 20px;
  clear: both;
  overflow: hidden;
}
.footer .bottom{
  background: #e4e0e0;
}
.liens_footer div{
    display: inline-block;
    vertical-align: top;
    padding-right: 9%;
}
.liens_footer div:last-child{
  padding-right: 0;
}
.liens_footer div a,
.liens_footer div strong{
  font-family: Montserrat, Arial, Helvetica, Sans-Serif;
  color: #333333;
}
.liens_footer div a{
  font-size: 12px;
  line-height: 21px;
  display: table;
}
.liens_footer div strong{
  font-weight: bold;
  font-size: 13px;
}

.liens_footer div a:hover{
  color: #4d4d4d;
  text-decoration: underline!important;
}
.footer .logo{
  float: left;
}
.footer .liens_footer{
  float: right;
  width: 100%;
  margin-bottom: 20px;
}
.copyright{
  text-align: center;
  padding-bottom: 4px;
  color: #3c3c3c;
  padding: 10px;
}
.copyright a{
  color: #3c3c3c;
}
.sociaux{
  
  text-align: center;
  padding-bottom: 0;
  width: 100%;
  display: inline-block;
}
.sociaux a{
  background-size: 30px 30px;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  display: inline-block;  
  margin: 0 5px;
}
.footer .logo{
  background: url("../images/icones/logo.png");
  background-repeat: no-repeat;
  width: 66px;
  height: 66px;
  display: block;
}
.footer .fb{
  background-image: url("../images/icones/facebook-icon.png");
}
.footer .tw{
  background-image: url("../images/icones/twitter-icon.png");
}
.footer .inst{
  background-image: url("../images/icones/instagram_j.png");
}
.sociaux span{
  position: relative;
  top: 0px;
  font-family:'MontserratSemiBold', Arial, Helvetica, Sans-Serif;
  color: #4b4e53;  
}
.status{
  display: inline-block;
  vertical-align: top;
  margin-top: 4px;
}
.status a{
  border: 1px solid #006aa1;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 7px;
  font-weight: normal;
  color: #006aa1 !important;
  font-weight: bold;;
}
.footer .status a:hover{
  text-decoration: none !important;
}
.status a,
.status a span{
  color: #006aa1;
  font-family:'MontserratSemiBold', Arial, Helvetica, Sans-Serif;
}
.lien-sociaux{  
  display: flex !important;
  justify-content: center;  
}

@media only screen and (max-width: 1100px) {
  .liens_footer div{
  width: 25%;
  margin-bottom: 20px;
  }
} 
@media only screen and (max-width: 960px) {
  .footer .logo{
  background: url("../images/logo_footer.jpg");
  width: 157px;
  height: 61px;
  margin: 0 auto 20px;
 }
 .footer .liens_footer{
  clear: both;
  float: none;
  width: 100%;
 }
 .liens_footer div{
  padding-left: 0;
  padding-right: 1%;
  width: 25%;
 }
}
@media only screen and (max-width: 760px) {
  .liens_footer div{
  width: 33%;
 }
 .sociaux{
  text-align: left;
 }
 .lien-sociaux{
  justify-content: start;
 }
}
@media only screen and (max-width: 610px) {
  .liens_footer div{
  width: 50%;
  padding: 0;
 }
  .sociaux span{
    display: block;
  }
  .lien-sociaux{
    justify-content: flex-start;
  }
  .sociaux{
    text-align: left;
  }
}
